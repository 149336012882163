
// @ts-ignore
import { AccessLogSearchRequest } from './access-log-search-request';

// @ts-ignore
import { AccessLogSearchResponse } from './access-log-search-response';

// @ts-ignore
import { AccountingCheckIfInvoiceHasDirectPaymentRequest } from './accounting-check-if-invoice-has-direct-payment-request';

// @ts-ignore
import { AccountingInvoicePaymentResponse } from './accounting-invoice-payment-response';

// @ts-ignore
import { AccountingPaymentPreferencesResponse } from './accounting-payment-preferences-response';

// @ts-ignore
import { AccountingUpdatePaymentGroupRequest } from './accounting-update-payment-group-request';

// @ts-ignore
import { AddAdhocInvoiceItemRequest } from './add-adhoc-invoice-item-request';

// @ts-ignore
import { AddAutoDiagnosisToEncounterRequest } from './add-auto-diagnosis-to-encounter-request';

// @ts-ignore
import { AddBillableItemsBillingRequest } from './add-billable-items-billing-request';

// @ts-ignore
import { AddDiagnosesToPerformedServicesRequest } from './add-diagnoses-to-performed-services-request';

// @ts-ignore
import { AddDiscountItemRequest } from './add-discount-item-request';

// @ts-ignore
import { AddDiscountItemsRequest } from './add-discount-items-request';

// @ts-ignore
import { AddDiscountRequest } from './add-discount-request';

// @ts-ignore
import { AddDynamicTestToDynamicScreenRequest } from './add-dynamic-test-to-dynamic-screen-request';

// @ts-ignore
import { AddFamilyMemberRequest } from './add-family-member-request';

// @ts-ignore
import { AddFamilyMemberWithEmergencyContactRequest } from './add-family-member-with-emergency-contact-request';

// @ts-ignore
import { AddInvoiceItemRequest } from './add-invoice-item-request';

// @ts-ignore
import { AddMasterDiagnosisToEncounterRequest } from './add-master-diagnosis-to-encounter-request';

// @ts-ignore
import { AddPatientOwnedFrameRequest } from './add-patient-owned-frame-request';

// @ts-ignore
import { AddPatientOwnedFrameResponse } from './add-patient-owned-frame-response';

// @ts-ignore
import { AddPerformedServicesRequest } from './add-performed-services-request';

// @ts-ignore
import { AddPqriAlertsToEncounterRequest } from './add-pqri-alerts-to-encounter-request';

// @ts-ignore
import { AdditionalClaimInformationCanadaRequest } from './additional-claim-information-canada-request';

// @ts-ignore
import { AdditionalClaimInformationCanadaResponse } from './additional-claim-information-canada-response';

// @ts-ignore
import { AdditionalClaimInformationResponse } from './additional-claim-information-response';

// @ts-ignore
import { AdditionalPreferencesRequest } from './additional-preferences-request';

// @ts-ignore
import { AdditionalPreferencesResponse } from './additional-preferences-response';

// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { AddressRequiredRequest } from './address-required-request';

// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { AdminAccessCodeRequest } from './admin-access-code-request';

// @ts-ignore
import { AdminAccessTimeRequest } from './admin-access-time-request';

// @ts-ignore
import { AdminAccessTimeResponse } from './admin-access-time-response';

// @ts-ignore
import { AdminAddWorkflowTestTemplateFieldOptionRequest } from './admin-add-workflow-test-template-field-option-request';

// @ts-ignore
import { AdminCopyEyefinityFeeScheduleToLocationRequest } from './admin-copy-eyefinity-fee-schedule-to-location-request';

// @ts-ignore
import { AdminCreateEmployeeExternalCredentialRequest } from './admin-create-employee-external-credential-request';

// @ts-ignore
import { AdminCreateEmployeeLoginDetailsRequest } from './admin-create-employee-login-details-request';

// @ts-ignore
import { AdminCreateEmployeeRequest } from './admin-create-employee-request';

// @ts-ignore
import { AdminCreateEyefinityFeeScheduleRequest } from './admin-create-eyefinity-fee-schedule-request';

// @ts-ignore
import { AdminCreatePracticeLocationDetailsRequest } from './admin-create-practice-location-details-request';

// @ts-ignore
import { AdminCreatePracticeLocationFileDeviceRequest } from './admin-create-practice-location-file-device-request';

// @ts-ignore
import { AdminCreatePracticeLocationRequest } from './admin-create-practice-location-request';

// @ts-ignore
import { AdminCreatePracticeLocationSerialDeviceRequest } from './admin-create-practice-location-serial-device-request';

// @ts-ignore
import { AdminCreateProviderInsuranceIdentifierRequest } from './admin-create-provider-insurance-identifier-request';

// @ts-ignore
import { AdminCreateWorkflowScreenTemplateRequest } from './admin-create-workflow-screen-template-request';

// @ts-ignore
import { AdminCreateWorkflowTestTemplateSvcTriggerRequest } from './admin-create-workflow-test-template-svc-trigger-request';

// @ts-ignore
import { AdminEditWorkflowTestTemplateFieldOptionRequest } from './admin-edit-workflow-test-template-field-option-request';

// @ts-ignore
import { AdminEmployeeDetailedResponse } from './admin-employee-detailed-response';

// @ts-ignore
import { AdminEmployeeExternalCredentialResponse } from './admin-employee-external-credential-response';

// @ts-ignore
import { AdminEmployeeExternalSystemsRequest } from './admin-employee-external-systems-request';

// @ts-ignore
import { AdminEmployeeExternalSystemsResponse } from './admin-employee-external-systems-response';

// @ts-ignore
import { AdminEmployeeForEmployeeListResponse } from './admin-employee-for-employee-list-response';

// @ts-ignore
import { AdminEmployeeLimitedResponse } from './admin-employee-limited-response';

// @ts-ignore
import { AdminEmployeeLocationAccessRequest } from './admin-employee-location-access-request';

// @ts-ignore
import { AdminEmployeeLocationAccessResponse } from './admin-employee-location-access-response';

// @ts-ignore
import { AdminEmployeeLoginDetailsResponse } from './admin-employee-login-details-response';

// @ts-ignore
import { AdminEmployeeLoginRestrictionRequest } from './admin-employee-login-restriction-request';

// @ts-ignore
import { AdminEmployeeLoginRestrictionResponse } from './admin-employee-login-restriction-response';

// @ts-ignore
import { AdminEmployeeProviderDetailsRequest } from './admin-employee-provider-details-request';

// @ts-ignore
import { AdminEmployeeProviderDetailsResponse } from './admin-employee-provider-details-response';

// @ts-ignore
import { AdminEmployeeRolesRequest } from './admin-employee-roles-request';

// @ts-ignore
import { AdminEmployeeRolesResponse } from './admin-employee-roles-response';

// @ts-ignore
import { AdminEncounterTemplateResponse } from './admin-encounter-template-response';

// @ts-ignore
import { AdminEyefinityFeeLensRequest } from './admin-eyefinity-fee-lens-request';

// @ts-ignore
import { AdminEyefinityFeeLensResponse } from './admin-eyefinity-fee-lens-response';

// @ts-ignore
import { AdminEyefinityFeeOptionRequest } from './admin-eyefinity-fee-option-request';

// @ts-ignore
import { AdminEyefinityFeeOptionResponse } from './admin-eyefinity-fee-option-response';

// @ts-ignore
import { AdminEyefinityFeeScheduleListResponse } from './admin-eyefinity-fee-schedule-list-response';

// @ts-ignore
import { AdminEyefinityFeeScheduleRequest } from './admin-eyefinity-fee-schedule-request';

// @ts-ignore
import { AdminEyefinityFeeScheduleResponse } from './admin-eyefinity-fee-schedule-response';

// @ts-ignore
import { AdminEyefinityFeeScheduleTemplateResponse } from './admin-eyefinity-fee-schedule-template-response';

// @ts-ignore
import { AdminEyefinityFeeServiceRequest } from './admin-eyefinity-fee-service-request';

// @ts-ignore
import { AdminEyefinityFeeServiceResponse } from './admin-eyefinity-fee-service-response';

// @ts-ignore
import { AdminFindWorkflowTestTemplateSvcTriggerRequest } from './admin-find-workflow-test-template-svc-trigger-request';

// @ts-ignore
import { AdminInsuranceCreditResponse } from './admin-insurance-credit-response';

// @ts-ignore
import { AdminInsuranceRefundResponse } from './admin-insurance-refund-response';

// @ts-ignore
import { AdminLocationOrderProcessingResponse } from './admin-location-order-processing-response';

// @ts-ignore
import { AdminLocationOrderProcessingVisionwebRequest } from './admin-location-order-processing-visionweb-request';

// @ts-ignore
import { AdminLocationOrderProcessingVisionwebResponse } from './admin-location-order-processing-visionweb-response';

// @ts-ignore
import { AdminLocationPhoneRequest } from './admin-location-phone-request';

// @ts-ignore
import { AdminLocationTaxResponse } from './admin-location-tax-response';

// @ts-ignore
import { AdminOpenEdgeCardReaderResponse } from './admin-open-edge-card-reader-response';

// @ts-ignore
import { AdminPracticeInsuranceCompanySummaryResponse } from './admin-practice-insurance-company-summary-response';

// @ts-ignore
import { AdminPracticeLocationAddressResponse } from './admin-practice-location-address-response';

// @ts-ignore
import { AdminPracticeLocationDetailsRequest } from './admin-practice-location-details-request';

// @ts-ignore
import { AdminPracticeLocationDetailsResponse } from './admin-practice-location-details-response';

// @ts-ignore
import { AdminPracticeLocationDistributionCenterRequest } from './admin-practice-location-distribution-center-request';

// @ts-ignore
import { AdminPracticeLocationDistributionCenterResponse } from './admin-practice-location-distribution-center-response';

// @ts-ignore
import { AdminPracticeLocationExternalSystemsResponse } from './admin-practice-location-external-systems-response';

// @ts-ignore
import { AdminPracticeLocationOfficeHoursResponse } from './admin-practice-location-office-hours-response';

// @ts-ignore
import { AdminPracticeLocationOrderProcessingRequest } from './admin-practice-location-order-processing-request';

// @ts-ignore
import { AdminPracticeLocationPhrRequest } from './admin-practice-location-phr-request';

// @ts-ignore
import { AdminPracticeLocationPhrResponse } from './admin-practice-location-phr-response';

// @ts-ignore
import { AdminPracticeLocationResponse } from './admin-practice-location-response';

// @ts-ignore
import { AdminSmartApplicationResponse } from './admin-smart-application-response';

// @ts-ignore
import { AdminUpdateEmployeeExternalCredentialRequest } from './admin-update-employee-external-credential-request';

// @ts-ignore
import { AdminUpdateEmployeeLoginDetailsRequest } from './admin-update-employee-login-details-request';

// @ts-ignore
import { AdminUpdateEmployeeRequest } from './admin-update-employee-request';

// @ts-ignore
import { AdminUpdateEyefinityFeeScheduleRequest } from './admin-update-eyefinity-fee-schedule-request';

// @ts-ignore
import { AdminUpdatePracticeLocationFileDeviceRequest } from './admin-update-practice-location-file-device-request';

// @ts-ignore
import { AdminUpdatePracticeLocationRequest } from './admin-update-practice-location-request';

// @ts-ignore
import { AdminUpdatePracticeLocationSerialDeviceRequest } from './admin-update-practice-location-serial-device-request';

// @ts-ignore
import { AdminUpdateProviderInsuranceIdentifierRequest } from './admin-update-provider-insurance-identifier-request';

// @ts-ignore
import { AdminUpdateWorkflowScreenTemplateRequest } from './admin-update-workflow-screen-template-request';

// @ts-ignore
import { AdminUpdateWorkflowTestTemplateRequest } from './admin-update-workflow-test-template-request';

// @ts-ignore
import { AdminUpdateWorkflowTestTemplateSvcTriggerRequest } from './admin-update-workflow-test-template-svc-trigger-request';

// @ts-ignore
import { AdminWorkflowScreenTemplateListResponse } from './admin-workflow-screen-template-list-response';

// @ts-ignore
import { AdminWorkflowScreenTemplateResponse } from './admin-workflow-screen-template-response';

// @ts-ignore
import { AdminWorkflowScreenTemplateTestResponse } from './admin-workflow-screen-template-test-response';

// @ts-ignore
import { AdminWorkflowTestTemplateFormFieldRequest } from './admin-workflow-test-template-form-field-request';

// @ts-ignore
import { AdminWorkflowTestTemplateListResponse } from './admin-workflow-test-template-list-response';

// @ts-ignore
import { AdminWorkflowTestTemplateSvcTriggerDetailResponse } from './admin-workflow-test-template-svc-trigger-detail-response';

// @ts-ignore
import { AdminWorkflowTestTemplateSvcTriggerResponse } from './admin-workflow-test-template-svc-trigger-response';

// @ts-ignore
import { AdvancedAgeReportRequest } from './advanced-age-report-request';

// @ts-ignore
import { AdvancedCostReportRequest } from './advanced-cost-report-request';

// @ts-ignore
import { AdvancedInventoryMinMaxReportRequest } from './advanced-inventory-min-max-report-request';

// @ts-ignore
import { AdvancedInventoryReportRequest } from './advanced-inventory-report-request';

// @ts-ignore
import { AdvancedSalesReportRequest } from './advanced-sales-report-request';

// @ts-ignore
import { AgingReportRequest } from './aging-report-request';

// @ts-ignore
import { AgingReportResponse } from './aging-report-response';

// @ts-ignore
import { AgreementDetailsResponse } from './agreement-details-response';

// @ts-ignore
import { AlertResponse } from './alert-response';

// @ts-ignore
import { AllergyListResponse } from './allergy-list-response';

// @ts-ignore
import { AllergyRequest } from './allergy-request';

// @ts-ignore
import { AllergyResponse } from './allergy-response';

// @ts-ignore
import { AllergyReviewHistoryRequest } from './allergy-review-history-request';

// @ts-ignore
import { AllergyReviewHistoryResponse } from './allergy-review-history-response';

// @ts-ignore
import { AmendmentListResponse } from './amendment-list-response';

// @ts-ignore
import { AmendmentResponse } from './amendment-response';

// @ts-ignore
import { AncillaryParticipantResponse } from './ancillary-participant-response';

// @ts-ignore
import { AppointmentCalendarResponse } from './appointment-calendar-response';

// @ts-ignore
import { AppointmentExamHistoryListResponse } from './appointment-exam-history-list-response';

// @ts-ignore
import { AppointmentHistoryResponse } from './appointment-history-response';

// @ts-ignore
import { AppointmentInsuranceVerificationResponse } from './appointment-insurance-verification-response';

// @ts-ignore
import { AppointmentReportRequest } from './appointment-report-request';

// @ts-ignore
import { AppointmentReportResponse } from './appointment-report-response';

// @ts-ignore
import { AppointmentResponse } from './appointment-response';

// @ts-ignore
import { AppointmentSidebarResponse } from './appointment-sidebar-response';

// @ts-ignore
import { AppointmentSlotCalendarResponse } from './appointment-slot-calendar-response';

// @ts-ignore
import { AppointmentSlotResponse } from './appointment-slot-response';

// @ts-ignore
import { AppointmentSlotSidebarResponse } from './appointment-slot-sidebar-response';

// @ts-ignore
import { ApproveInvoiceRequest } from './approve-invoice-request';

// @ts-ignore
import { AskQuestionRequest } from './ask-question-request';

// @ts-ignore
import { AssessmentAutoDiagnosisResponse } from './assessment-auto-diagnosis-response';

// @ts-ignore
import { AssessmentDiagnosisReviewRequest } from './assessment-diagnosis-review-request';

// @ts-ignore
import { AssessmentDiagnosisReviewResponse } from './assessment-diagnosis-review-response';

// @ts-ignore
import { AssessmentPersonDiagnosisResponse } from './assessment-person-diagnosis-response';

// @ts-ignore
import { AssessmentPracticeDiagnosisResponse } from './assessment-practice-diagnosis-response';

// @ts-ignore
import { AssessmentResponse } from './assessment-response';

// @ts-ignore
import { AssignFeeScheduleItemListRequest } from './assign-fee-schedule-item-list-request';

// @ts-ignore
import { AssignFeeScheduleItemRequest } from './assign-fee-schedule-item-request';

// @ts-ignore
import { AssignRelatedCategoriesRequest } from './assign-related-categories-request';

// @ts-ignore
import { AssociateMeasurementWithOrderRequest } from './associate-measurement-with-order-request';

// @ts-ignore
import { AttributeDropdownResponse } from './attribute-dropdown-response';

// @ts-ignore
import { AuthorizeContactLensPrescriptionRequest } from './authorize-contact-lens-prescription-request';

// @ts-ignore
import { AuthorizeEyeglassPrescriptionsRequest } from './authorize-eyeglass-prescriptions-request';

// @ts-ignore
import { AuthorizeMedicationPrescriptionRequest } from './authorize-medication-prescription-request';

// @ts-ignore
import { BarcodeSearchRequest } from './barcode-search-request';

// @ts-ignore
import { BarcodeSearchResponse } from './barcode-search-response';

// @ts-ignore
import { BaseAgeReportResponse } from './base-age-report-response';

// @ts-ignore
import { BaseCostReportResponse } from './base-cost-report-response';

// @ts-ignore
import { BaseInventoryReportResponse } from './base-inventory-report-response';

// @ts-ignore
import { BaseSalesReportResponse } from './base-sales-report-response';

// @ts-ignore
import { BasicAgeReportRequest } from './basic-age-report-request';

// @ts-ignore
import { BasicCostReportRequest } from './basic-cost-report-request';

// @ts-ignore
import { BasicInventoryMinMaxReportRequest } from './basic-inventory-min-max-report-request';

// @ts-ignore
import { BasicInventoryReportRequest } from './basic-inventory-report-request';

// @ts-ignore
import { BasicSalesReportRequest } from './basic-sales-report-request';

// @ts-ignore
import { BillableItemProductResponse } from './billable-item-product-response';

// @ts-ignore
import { BillingInvoiceResponse } from './billing-invoice-response';

// @ts-ignore
import { BillingPayerResponse } from './billing-payer-response';

// @ts-ignore
import { BillingServiceResponse } from './billing-service-response';

// @ts-ignore
import { BridgeExportResponse } from './bridge-export-response';

// @ts-ignore
import { BridgeExportTokenRequest } from './bridge-export-token-request';

// @ts-ignore
import { BulkCancelOrdersRequest } from './bulk-cancel-orders-request';

// @ts-ignore
import { BulkNotifyPatientsRequest } from './bulk-notify-patients-request';

// @ts-ignore
import { BulkUpdateOrderRequest } from './bulk-update-order-request';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

// @ts-ignore
import { BusyTimeCalendarResponse } from './busy-time-calendar-response';

// @ts-ignore
import { BusyTimeResponse } from './busy-time-response';

// @ts-ignore
import { BusyTimeSidebarResponse } from './busy-time-sidebar-response';

// @ts-ignore
import { CanSendPatientMessageRequest } from './can-send-patient-message-request';

// @ts-ignore
import { CancelAppointmentRequest } from './cancel-appointment-request';

// @ts-ignore
import { CancelEncounterRequest } from './cancel-encounter-request';

// @ts-ignore
import { CancelOrderRequest } from './cancel-order-request';

// @ts-ignore
import { CancelPrescriptionRequest } from './cancel-prescription-request';

// @ts-ignore
import { CarePlanItemHistoryResponse } from './care-plan-item-history-response';

// @ts-ignore
import { CarePlanItemResponse } from './care-plan-item-response';

// @ts-ignore
import { CarePlanItemTemplateEducationResponse } from './care-plan-item-template-education-response';

// @ts-ignore
import { CarePlanItemTemplateGeneralResponse } from './care-plan-item-template-general-response';

// @ts-ignore
import { CarePlanItemTemplateGoalResponse } from './care-plan-item-template-goal-response';

// @ts-ignore
import { CarePlanItemTemplateLabOrderResponse } from './care-plan-item-template-lab-order-response';

// @ts-ignore
import { CarePlanItemTemplateListResponse } from './care-plan-item-template-list-response';

// @ts-ignore
import { CarePlanItemTemplateMedicationPrescriptionResponse } from './care-plan-item-template-medication-prescription-response';

// @ts-ignore
import { CarePlanItemTemplateRecallResponse } from './care-plan-item-template-recall-response';

// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { CarePlanTemplateListResponse } from './care-plan-template-list-response';

// @ts-ignore
import { CarePlanTemplateResponse } from './care-plan-template-response';

// @ts-ignore
import { CategoryResponse } from './category-response';

// @ts-ignore
import { CcdaSectionsPreferenceRequest } from './ccda-sections-preference-request';

// @ts-ignore
import { CcdaSectionsPreferenceResponse } from './ccda-sections-preference-response';

// @ts-ignore
import { ChangeEyefinityOrderRequest } from './change-eyefinity-order-request';

// @ts-ignore
import { ChangeOrderRequest } from './change-order-request';

// @ts-ignore
import { CheckOdOsLocationProductIdsActiveRequest } from './check-od-os-location-product-ids-active-request';

// @ts-ignore
import { CheckoutTaskResponse } from './checkout-task-response';

// @ts-ignore
import { CityStateProvinceResponse } from './city-state-province-response';

// @ts-ignore
import { ClaimProcessingPreferencesResponse } from './claim-processing-preferences-response';

// @ts-ignore
import { ClaimSearchRequest } from './claim-search-request';

// @ts-ignore
import { ClaimSubmitErrorResponse } from './claim-submit-error-response';

// @ts-ignore
import { ClaimSubmitResponse } from './claim-submit-response';

// @ts-ignore
import { ClaimsInsuranceClaimResponse } from './claims-insurance-claim-response';

// @ts-ignore
import { ClaimsProcessorAttributeResponse } from './claims-processor-attribute-response';

// @ts-ignore
import { CodeEncounterRequest } from './code-encounter-request';

// @ts-ignore
import { CollectionsInvoiceTransferSplitRequest } from './collections-invoice-transfer-split-request';

// @ts-ignore
import { CommunicationsPreferencesResponse } from './communications-preferences-response';

// @ts-ignore
import { ConditionRelatedToResponse } from './condition-related-to-response';

// @ts-ignore
import { ConfirmAppointmentRequest } from './confirm-appointment-request';

// @ts-ignore
import { ConnectCampaignListResponse } from './connect-campaign-list-response';

// @ts-ignore
import { ConnectCampaignMessageListResponse } from './connect-campaign-message-list-response';

// @ts-ignore
import { ConnectCampaignMessagePagedResponse } from './connect-campaign-message-paged-response';

// @ts-ignore
import { ConnectCampaignMessagePreviewRequest } from './connect-campaign-message-preview-request';

// @ts-ignore
import { ConnectCampaignResponse } from './connect-campaign-response';

// @ts-ignore
import { ConnectCampaignSendTestEmailRequest } from './connect-campaign-send-test-email-request';

// @ts-ignore
import { ConnectCampaignTemplateResponse } from './connect-campaign-template-response';

// @ts-ignore
import { ConnectMessageForPatientAppointmentRequest } from './connect-message-for-patient-appointment-request';

// @ts-ignore
import { ConnectMessageForPatientRecallRequest } from './connect-message-for-patient-recall-request';

// @ts-ignore
import { ConnectMessageForWaitingListRequest } from './connect-message-for-waiting-list-request';

// @ts-ignore
import { ConnectPracticeResponse } from './connect-practice-response';

// @ts-ignore
import { ConnectSummaryConnectMessageListResponse } from './connect-summary-connect-message-list-response';

// @ts-ignore
import { ConsentFormListResponse } from './consent-form-list-response';

// @ts-ignore
import { ConsentFormResponse } from './consent-form-response';

// @ts-ignore
import { ContactDemographicsRequest } from './contact-demographics-request';

// @ts-ignore
import { ContactInformationRequest } from './contact-information-request';

// @ts-ignore
import { ContactLensAgeReportResponse } from './contact-lens-age-report-response';

// @ts-ignore
import { ContactLensCostReportResponse } from './contact-lens-cost-report-response';

// @ts-ignore
import { ContactLensEyeHybridRequest } from './contact-lens-eye-hybrid-request';

// @ts-ignore
import { ContactLensEyeHybridResponse } from './contact-lens-eye-hybrid-response';

// @ts-ignore
import { ContactLensEyeOptionsResponse } from './contact-lens-eye-options-response';

// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { ContactLensEyeRgpRequest } from './contact-lens-eye-rgp-request';

// @ts-ignore
import { ContactLensEyeRgpResponse } from './contact-lens-eye-rgp-response';

// @ts-ignore
import { ContactLensEyeSoftRequest } from './contact-lens-eye-soft-request';

// @ts-ignore
import { ContactLensEyeSoftResponse } from './contact-lens-eye-soft-response';

// @ts-ignore
import { ContactLensHistoryResponse } from './contact-lens-history-response';

// @ts-ignore
import { ContactLensInventoryReportResponse } from './contact-lens-inventory-report-response';

// @ts-ignore
import { ContactLensOrderResponse } from './contact-lens-order-response';

// @ts-ignore
import { ContactLensOrderSearchResponse } from './contact-lens-order-search-response';

// @ts-ignore
import { ContactLensPrescriptionDetailsResponse } from './contact-lens-prescription-details-response';

// @ts-ignore
import { ContactLensPrescriptionLookupResponse } from './contact-lens-prescription-lookup-response';

// @ts-ignore
import { ContactLensPrescriptionResponse } from './contact-lens-prescription-response';

// @ts-ignore
import { ContactLensPropertyValuesResponse } from './contact-lens-property-values-response';

// @ts-ignore
import { ContactLensSalesReportResponse } from './contact-lens-sales-report-response';

// @ts-ignore
import { ContactLensTrialDetailResponse } from './contact-lens-trial-detail-response';

// @ts-ignore
import { ContactLensTrialListResponse } from './contact-lens-trial-list-response';

// @ts-ignore
import { ContactLensTrialOrderResponse } from './contact-lens-trial-order-response';

// @ts-ignore
import { ContactLensTrialResponse } from './contact-lens-trial-response';

// @ts-ignore
import { ContactListResponse } from './contact-list-response';

// @ts-ignore
import { ContactPersonNameResponse } from './contact-person-name-response';

// @ts-ignore
import { ContactResponse } from './contact-response';

// @ts-ignore
import { ContactWithEmergencyContactResponse } from './contact-with-emergency-contact-response';

// @ts-ignore
import { ContinuityOfCareImportResultResponse } from './continuity-of-care-import-result-response';

// @ts-ignore
import { CopyFeeScheduleRangeRequest } from './copy-fee-schedule-range-request';

// @ts-ignore
import { CqmReportRequest } from './cqm-report-request';

// @ts-ignore
import { CqmReportResponse } from './cqm-report-response';

// @ts-ignore
import { CreateAlertRequest } from './create-alert-request';

// @ts-ignore
import { CreateAllergyReviewHistoryRequest } from './create-allergy-review-history-request';

// @ts-ignore
import { CreateAmendmentRequest } from './create-amendment-request';

// @ts-ignore
import { CreateAncillaryParticipantRequest } from './create-ancillary-participant-request';

// @ts-ignore
import { CreateAppointmentRequest } from './create-appointment-request';

// @ts-ignore
import { CreateAppointmentSlotRequest } from './create-appointment-slot-request';

// @ts-ignore
import { CreateAssessmentDiagnosisReviewRequest } from './create-assessment-diagnosis-review-request';

// @ts-ignore
import { CreateAssessmentPersonDiagnosisRequest } from './create-assessment-person-diagnosis-request';

// @ts-ignore
import { CreateAttributeRequest } from './create-attribute-request';

// @ts-ignore
import { CreateBillingInvoiceRequest } from './create-billing-invoice-request';

// @ts-ignore
import { CreateBusyTimeRequest } from './create-busy-time-request';

// @ts-ignore
import { CreateCarePlanItemTemplateEducationRequest } from './create-care-plan-item-template-education-request';

// @ts-ignore
import { CreateCarePlanItemTemplateGeneralRequest } from './create-care-plan-item-template-general-request';

// @ts-ignore
import { CreateCarePlanItemTemplateGoalRequest } from './create-care-plan-item-template-goal-request';

// @ts-ignore
import { CreateCarePlanItemTemplateLabOrderRequest } from './create-care-plan-item-template-lab-order-request';

// @ts-ignore
import { CreateCarePlanItemTemplateMedicationPrescriptionRequest } from './create-care-plan-item-template-medication-prescription-request';

// @ts-ignore
import { CreateCarePlanItemTemplateRecallRequest } from './create-care-plan-item-template-recall-request';

// @ts-ignore
import { CreateCarePlanItemTemplateRequest } from './create-care-plan-item-template-request';

// @ts-ignore
import { CreateCarePlanTemplateRequest } from './create-care-plan-template-request';

// @ts-ignore
import { CreateCategoryRequest } from './create-category-request';

// @ts-ignore
import { CreateCheckoutTaskRequest } from './create-checkout-task-request';

// @ts-ignore
import { CreateConnectCampaignDraftRequest } from './create-connect-campaign-draft-request';

// @ts-ignore
import { CreateConnectCampaignScheduleRequest } from './create-connect-campaign-schedule-request';

// @ts-ignore
import { CreateConnectPracticeRequest } from './create-connect-practice-request';

// @ts-ignore
import { CreateContactLensOrderRequest } from './create-contact-lens-order-request';

// @ts-ignore
import { CreateContactLensPrescriptionRequest } from './create-contact-lens-prescription-request';

// @ts-ignore
import { CreateContactLensTrialOrderBasicRequest } from './create-contact-lens-trial-order-basic-request';

// @ts-ignore
import { CreateContactLensTrialOrderRequest } from './create-contact-lens-trial-order-request';

// @ts-ignore
import { CreateContactLensTrialRequest } from './create-contact-lens-trial-request';

// @ts-ignore
import { CreateContactRequest } from './create-contact-request';

// @ts-ignore
import { CreateDiagnosisReviewHistoryRequest } from './create-diagnosis-review-history-request';

// @ts-ignore
import { CreateDrugAllergyRequest } from './create-drug-allergy-request';

// @ts-ignore
import { CreateEmployeeOfficeHoursRequest } from './create-employee-office-hours-request';

// @ts-ignore
import { CreateEncounterRequest } from './create-encounter-request';

// @ts-ignore
import { CreateEncounterRoleRequest } from './create-encounter-role-request';

// @ts-ignore
import { CreateEncounterTemplateRequest } from './create-encounter-template-request';

// @ts-ignore
import { CreateExternalCredentialForPracticeLocationRequest } from './create-external-credential-for-practice-location-request';

// @ts-ignore
import { CreateExternalDataRequest } from './create-external-data-request';

// @ts-ignore
import { CreateExternalProviderRequest } from './create-external-provider-request';

// @ts-ignore
import { CreateEyeglassOrderRequest } from './create-eyeglass-order-request';

// @ts-ignore
import { CreateEyeglassPrescriptionBaseRequest } from './create-eyeglass-prescription-base-request';

// @ts-ignore
import { CreateEyeglassPrescriptionFromRefractionRequest } from './create-eyeglass-prescription-from-refraction-request';

// @ts-ignore
import { CreateEyeglassPrescriptionRequest } from './create-eyeglass-prescription-request';

// @ts-ignore
import { CreateEyeglassPrescriptionsRequest } from './create-eyeglass-prescriptions-request';

// @ts-ignore
import { CreateFamilyHealthHistoryRequest } from './create-family-health-history-request';

// @ts-ignore
import { CreateFamilyHealthHistoryReviewSetRequest } from './create-family-health-history-review-set-request';

// @ts-ignore
import { CreateFeatureFlagEmployeeRequest } from './create-feature-flag-employee-request';

// @ts-ignore
import { CreateFeeScheduleRangeRequest } from './create-fee-schedule-range-request';

// @ts-ignore
import { CreateFeeScheduleRequest } from './create-fee-schedule-request';

// @ts-ignore
import { CreateFinanceChargePlanPeriodRequest } from './create-finance-charge-plan-period-request';

// @ts-ignore
import { CreateFinanceChargePlanRequest } from './create-finance-charge-plan-request';

// @ts-ignore
import { CreateFolderRequest } from './create-folder-request';

// @ts-ignore
import { CreateFrameOrderRequest } from './create-frame-order-request';

// @ts-ignore
import { CreateFramesDataSubscriptionRequest } from './create-frames-data-subscription-request';

// @ts-ignore
import { CreateGeneralMedicationPrescriptionRequest } from './create-general-medication-prescription-request';

// @ts-ignore
import { CreateGuestInvoiceRequest } from './create-guest-invoice-request';

// @ts-ignore
import { CreateInterviewTemplateRequest } from './create-interview-template-request';

// @ts-ignore
import { CreateInvoiceRequest } from './create-invoice-request';

// @ts-ignore
import { CreateItemQuantityChangePurchaseOrderRequest } from './create-item-quantity-change-purchase-order-request';

// @ts-ignore
import { CreateItemQuantityChangeRequest } from './create-item-quantity-change-request';

// @ts-ignore
import { CreateLabOrderRequest } from './create-lab-order-request';

// @ts-ignore
import { CreateManufacturerRequest } from './create-manufacturer-request';

// @ts-ignore
import { CreateMedicationPrescriptionRequest } from './create-medication-prescription-request';

// @ts-ignore
import { CreateMedicationReviewHistoryRequest } from './create-medication-review-history-request';

// @ts-ignore
import { CreateMessageFolderRequest } from './create-message-folder-request';

// @ts-ignore
import { CreateMessageParticipantRequest } from './create-message-participant-request';

// @ts-ignore
import { CreateMessageRequest } from './create-message-request';

// @ts-ignore
import { CreateNoteRequest } from './create-note-request';

// @ts-ignore
import { CreateOfficeClosedRequest } from './create-office-closed-request';

// @ts-ignore
import { CreateOpenEdgeCardReaderRequest } from './create-open-edge-card-reader-request';

// @ts-ignore
import { CreateOpenEdgeSettingsRequest } from './create-open-edge-settings-request';

// @ts-ignore
import { CreateOrUpdateHistoryDiagnosisCarePlanItemRequest } from './create-or-update-history-diagnosis-care-plan-item-request';

// @ts-ignore
import { CreateOrderFromEyeglassPrescriptionRequest } from './create-order-from-eyeglass-prescription-request';

// @ts-ignore
import { CreateOrderRequest } from './create-order-request';

// @ts-ignore
import { CreateOtherAllergyRequest } from './create-other-allergy-request';

// @ts-ignore
import { CreatePatientBasicInformationRequest } from './create-patient-basic-information-request';

// @ts-ignore
import { CreatePatientCommunicationPreferencesRequest } from './create-patient-communication-preferences-request';

// @ts-ignore
import { CreatePatientConsentRequest } from './create-patient-consent-request';

// @ts-ignore
import { CreatePatientCreditRequest } from './create-patient-credit-request';

// @ts-ignore
import { CreatePatientDocumentRequest } from './create-patient-document-request';

// @ts-ignore
import { CreatePatientFamilyDemographicsRequest } from './create-patient-family-demographics-request';

// @ts-ignore
import { CreatePatientFamilyInformationRequest } from './create-patient-family-information-request';

// @ts-ignore
import { CreatePatientFamilyInsuranceRequest } from './create-patient-family-insurance-request';

// @ts-ignore
import { CreatePatientFileRequest } from './create-patient-file-request';

// @ts-ignore
import { CreatePatientGeneralBenefitsRequest } from './create-patient-general-benefits-request';

// @ts-ignore
import { CreatePatientHealthConcernRequest } from './create-patient-health-concern-request';

// @ts-ignore
import { CreatePatientHealthGoalRequest } from './create-patient-health-goal-request';

// @ts-ignore
import { CreatePatientImplantableDeviceRequest } from './create-patient-implantable-device-request';

// @ts-ignore
import { CreatePatientInsuranceBasicInformationRequest } from './create-patient-insurance-basic-information-request';

// @ts-ignore
import { CreatePatientMaterialBenefitsRequest } from './create-patient-material-benefits-request';

// @ts-ignore
import { CreatePatientOngoingCareRequest } from './create-patient-ongoing-care-request';

// @ts-ignore
import { CreatePatientOtherInformationRequest } from './create-patient-other-information-request';

// @ts-ignore
import { CreatePatientPharmacyRequest } from './create-patient-pharmacy-request';

// @ts-ignore
import { CreatePatientPhoneInformationRequest } from './create-patient-phone-information-request';

// @ts-ignore
import { CreatePatientProviderRequest } from './create-patient-provider-request';

// @ts-ignore
import { CreatePatientRecallRequest } from './create-patient-recall-request';

// @ts-ignore
import { CreatePatientRequest } from './create-patient-request';

// @ts-ignore
import { CreatePatientTargetIopRequest } from './create-patient-target-iop-request';

// @ts-ignore
import { CreatePatientVaccinationRequest } from './create-patient-vaccination-request';

// @ts-ignore
import { CreatePaymentGroupRequest } from './create-payment-group-request';

// @ts-ignore
import { CreatePaymentRequest } from './create-payment-request';

// @ts-ignore
import { CreatePersonDiagnosisRequest } from './create-person-diagnosis-request';

// @ts-ignore
import { CreatePersonInsuranceRequest } from './create-person-insurance-request';

// @ts-ignore
import { CreatePharmacyRequest } from './create-pharmacy-request';

// @ts-ignore
import { CreatePracticeConsentFormRequest } from './create-practice-consent-form-request';

// @ts-ignore
import { CreatePracticeDiagnosisRequest } from './create-practice-diagnosis-request';

// @ts-ignore
import { CreatePracticeDrugRequest } from './create-practice-drug-request';

// @ts-ignore
import { CreatePracticeInsuranceCompanyModalRequest } from './create-practice-insurance-company-modal-request';

// @ts-ignore
import { CreatePracticeInsuranceCompanyRequest } from './create-practice-insurance-company-request';

// @ts-ignore
import { CreatePracticeInsuranceRemittanceCodeAdjustmentRequest } from './create-practice-insurance-remittance-code-adjustment-request';

// @ts-ignore
import { CreatePracticeRemittanceCodeAdjustmentRequest } from './create-practice-remittance-code-adjustment-request';

// @ts-ignore
import { CreatePrescriptionOrderRequest } from './create-prescription-order-request';

// @ts-ignore
import { CreatePresentIllnessRequest } from './create-present-illness-request';

// @ts-ignore
import { CreatePresentIllnessesFromDiagnosesRequest } from './create-present-illnesses-from-diagnoses-request';

// @ts-ignore
import { CreateProductBrandCollectionRequest } from './create-product-brand-collection-request';

// @ts-ignore
import { CreateProductBrandRequest } from './create-product-brand-request';

// @ts-ignore
import { CreateProfessionalTimeAdjustmentRequest } from './create-professional-time-adjustment-request';

// @ts-ignore
import { CreateProviderInsuranceIdentifierRequest } from './create-provider-insurance-identifier-request';

// @ts-ignore
import { CreateQueryRequest } from './create-query-request';

// @ts-ignore
import { CreateQueryTriggerRequest } from './create-query-trigger-request';

// @ts-ignore
import { CreateRecurPatternDailyRequest } from './create-recur-pattern-daily-request';

// @ts-ignore
import { CreateRecurPatternMonthlyRequest } from './create-recur-pattern-monthly-request';

// @ts-ignore
import { CreateRecurPatternWeeklyRequest } from './create-recur-pattern-weekly-request';

// @ts-ignore
import { CreateRecurPatternYearlyRequest } from './create-recur-pattern-yearly-request';

// @ts-ignore
import { CreateRecurringDefinitionRequest } from './create-recurring-definition-request';

// @ts-ignore
import { CreateReferenceDataRequest } from './create-reference-data-request';

// @ts-ignore
import { CreateRefundPatientRequest } from './create-refund-patient-request';

// @ts-ignore
import { CreateRelatedCategoryRequest } from './create-related-category-request';

// @ts-ignore
import { CreateRescheduledAppointmentRequest } from './create-rescheduled-appointment-request';

// @ts-ignore
import { CreateRevClearExternalCredentialRequest } from './create-rev-clear-external-credential-request';

// @ts-ignore
import { CreateSalesRepresentativeRequest } from './create-sales-representative-request';

// @ts-ignore
import { CreateSurgeryHistoryRequest } from './create-surgery-history-request';

// @ts-ignore
import { CreateThirdPartyAdministratorRequest } from './create-third-party-administrator-request';

// @ts-ignore
import { CreateTrizettoExternalCredentialRequest } from './create-trizetto-external-credential-request';

// @ts-ignore
import { CreateTwoWayTextConversationRequest } from './create-two-way-text-conversation-request';

// @ts-ignore
import { CreateTwoWayTextMessageRequest } from './create-two-way-text-message-request';

// @ts-ignore
import { CreateUserRoleRequest } from './create-user-role-request';

// @ts-ignore
import { CreateVendorLocationConfigRequest } from './create-vendor-location-config-request';

// @ts-ignore
import { CreateVendorRequest } from './create-vendor-request';

// @ts-ignore
import { CreateWaitingListRequest } from './create-waiting-list-request';

// @ts-ignore
import { CreateWebServiceUserRequest } from './create-web-service-user-request';

// @ts-ignore
import { CreateWorkflowStepTemplateRequest } from './create-workflow-step-template-request';

// @ts-ignore
import { CreditInsuranceResponse } from './credit-insurance-response';

// @ts-ignore
import { CreditPatientResponse } from './credit-patient-response';

// @ts-ignore
import { CustomFilterPreferencesResponse } from './custom-filter-preferences-response';

// @ts-ignore
import { CustomerAgreementsResponse } from './customer-agreements-response';

// @ts-ignore
import { CustomerContactsResponse } from './customer-contacts-response';

// @ts-ignore
import { CustomerContactsUpdateRequest } from './customer-contacts-update-request';

// @ts-ignore
import { CustomerSmartflowAgreementsResponse } from './customer-smartflow-agreements-response';

// @ts-ignore
import { DailyOfficeHoursAdminResponse } from './daily-office-hours-admin-response';

// @ts-ignore
import { DailyOfficeHoursResponse } from './daily-office-hours-response';

// @ts-ignore
import { DataLogDiffResponse } from './data-log-diff-response';

// @ts-ignore
import { DeceasePatientRequest } from './decease-patient-request';

// @ts-ignore
import { DeleteClaimsRequest } from './delete-claims-request';

// @ts-ignore
import { DeleteContactRequest } from './delete-contact-request';

// @ts-ignore
import { DeleteFolderRequest } from './delete-folder-request';

// @ts-ignore
import { DeleteMessageParticipantPersonsRequest } from './delete-message-participant-persons-request';

// @ts-ignore
import { DeleteProviderInsuranceIdentifierRequest } from './delete-provider-insurance-identifier-request';

// @ts-ignore
import { DeleteScheduleItemRequest } from './delete-schedule-item-request';

// @ts-ignore
import { DepositSlipSearchRequest } from './deposit-slip-search-request';

// @ts-ignore
import { DepositSlipSearchResponse } from './deposit-slip-search-response';

// @ts-ignore
import { DepositsReportRequest } from './deposits-report-request';

// @ts-ignore
import { DepositsReportResponse } from './deposits-report-response';

// @ts-ignore
import { DiagnosisReviewHistoryResponse } from './diagnosis-review-history-response';

// @ts-ignore
import { DiagnosisSearchRequest } from './diagnosis-search-request';

// @ts-ignore
import { DiagnosisSearchResponse } from './diagnosis-search-response';

// @ts-ignore
import { DispenseOrderRequest } from './dispense-order-request';

// @ts-ignore
import { DrugAllergyImportResponse } from './drug-allergy-import-response';

// @ts-ignore
import { DrugAllergyResponse } from './drug-allergy-response';

// @ts-ignore
import { DrugClassResponse } from './drug-class-response';

// @ts-ignore
import { DrugSearchRequest } from './drug-search-request';

// @ts-ignore
import { DrugSearchResponse } from './drug-search-response';

// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { DynamicScreenResponse } from './dynamic-screen-response';

// @ts-ignore
import { DynamicTestHistoryResponse } from './dynamic-test-history-response';

// @ts-ignore
import { DynamicTestResponse } from './dynamic-test-response';

// @ts-ignore
import { EClaimsInsuranceClaimResponse } from './e-claims-insurance-claim-response';

// @ts-ignore
import { EClaimsSearchRequest } from './e-claims-search-request';

// @ts-ignore
import { EmergencyContactStatusResponse } from './emergency-contact-status-response';

// @ts-ignore
import { EmployeeEditProfileRequest } from './employee-edit-profile-request';

// @ts-ignore
import { EmployeeEditProfileResponse } from './employee-edit-profile-response';

// @ts-ignore
import { EmployeeExternalCredentialsRequest } from './employee-external-credentials-request';

// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { EmployeeOfficeHoursCalendarResponse } from './employee-office-hours-calendar-response';

// @ts-ignore
import { EmployeeOfficeHoursResponse } from './employee-office-hours-response';

// @ts-ignore
import { EmployeeOfficeHoursSidebarResponse } from './employee-office-hours-sidebar-response';

// @ts-ignore
import { EmployeePreferencesRequest } from './employee-preferences-request';

// @ts-ignore
import { EmployeePreferencesResponse } from './employee-preferences-response';

// @ts-ignore
import { EmployeeResponse } from './employee-response';

// @ts-ignore
import { EmployeeRxntCredentialResponse } from './employee-rxnt-credential-response';

// @ts-ignore
import { EmployeeWithSecurityRolesResponse } from './employee-with-security-roles-response';

// @ts-ignore
import { EncounterAddCarePlanItemsToPersonDiagnosisRequest } from './encounter-add-care-plan-items-to-person-diagnosis-request';

// @ts-ignore
import { EncounterAddCarePlanTemplateToPersonDiagnosesRequest } from './encounter-add-care-plan-template-to-person-diagnoses-request';

// @ts-ignore
import { EncounterAutoCodePreviewResponse } from './encounter-auto-code-preview-response';

// @ts-ignore
import { EncounterCarePlanItemEducationResponse } from './encounter-care-plan-item-education-response';

// @ts-ignore
import { EncounterCarePlanItemGeneralResponse } from './encounter-care-plan-item-general-response';

// @ts-ignore
import { EncounterContactLensTrialRequest } from './encounter-contact-lens-trial-request';

// @ts-ignore
import { EncounterCreateCarePlanItemForPersonDiagnosisRequest } from './encounter-create-care-plan-item-for-person-diagnosis-request';

// @ts-ignore
import { EncounterCreateCarePlanItemsForPersonDiagnosesRequest } from './encounter-create-care-plan-items-for-person-diagnoses-request';

// @ts-ignore
import { EncounterCreateContactLensTrialRequest } from './encounter-create-contact-lens-trial-request';

// @ts-ignore
import { EncounterMedicalDecisionMakingCodingResponse } from './encounter-medical-decision-making-coding-response';

// @ts-ignore
import { EncounterProfessionalTimeCodingResponse } from './encounter-professional-time-coding-response';

// @ts-ignore
import { EncounterPullForwardTableResponse } from './encounter-pull-forward-table-response';

// @ts-ignore
import { EncounterReportRequest } from './encounter-report-request';

// @ts-ignore
import { EncounterReportResponse } from './encounter-report-response';

// @ts-ignore
import { EncounterResponse } from './encounter-response';

// @ts-ignore
import { EncounterReviewDetailResponse } from './encounter-review-detail-response';

// @ts-ignore
import { EncounterReviewGeneralInformationResponse } from './encounter-review-general-information-response';

// @ts-ignore
import { EncounterReviewGroupResponse } from './encounter-review-group-response';

// @ts-ignore
import { EncounterRoleResponse } from './encounter-role-response';

// @ts-ignore
import { EncounterStatusesResponse } from './encounter-statuses-response';

// @ts-ignore
import { EncounterStopCarePlanItemForPersonDiagnosisRequest } from './encounter-stop-care-plan-item-for-person-diagnosis-request';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { EncounterTemplateListResponse } from './encounter-template-list-response';

// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

// @ts-ignore
import { EncounterUpdateCarePlanItemEducationRequest } from './encounter-update-care-plan-item-education-request';

// @ts-ignore
import { EncounterUpdateCarePlanItemGeneralRequest } from './encounter-update-care-plan-item-general-request';

// @ts-ignore
import { EncounterUpdateCarePlanItemRecallRequest } from './encounter-update-care-plan-item-recall-request';

// @ts-ignore
import { EncounterUpdateContactLensTrialRequest } from './encounter-update-contact-lens-trial-request';

// @ts-ignore
import { ExternalCredentialLocationRequest } from './external-credential-location-request';

// @ts-ignore
import { ExternalCredentialLocationResponse } from './external-credential-location-response';

// @ts-ignore
import { ExternalDataListResponse } from './external-data-list-response';

// @ts-ignore
import { ExternalDataResponse } from './external-data-response';

// @ts-ignore
import { ExternalMedicationPrescriptionResponse } from './external-medication-prescription-response';

// @ts-ignore
import { ExternalProviderDropdownResponse } from './external-provider-dropdown-response';

// @ts-ignore
import { ExternalProviderListResponse } from './external-provider-list-response';

// @ts-ignore
import { ExternalProviderResponse } from './external-provider-response';

// @ts-ignore
import { EyefinityContactLensOrderResponse } from './eyefinity-contact-lens-order-response';

// @ts-ignore
import { EyefinityEyeglassOrderResponse } from './eyefinity-eyeglass-order-response';

// @ts-ignore
import { EyefinityFrameOrderResponse } from './eyefinity-frame-order-response';

// @ts-ignore
import { EyefinityListItemRequest } from './eyefinity-list-item-request';

// @ts-ignore
import { EyefinityListItemResponse } from './eyefinity-list-item-response';

// @ts-ignore
import { EyefinityOrderItemRequest } from './eyefinity-order-item-request';

// @ts-ignore
import { EyefinityOrderItemResponse } from './eyefinity-order-item-response';

// @ts-ignore
import { EyefinityOrderResponse } from './eyefinity-order-response';

// @ts-ignore
import { EyeglassFrameAgeReportResponse } from './eyeglass-frame-age-report-response';

// @ts-ignore
import { EyeglassFrameCostReportResponse } from './eyeglass-frame-cost-report-response';

// @ts-ignore
import { EyeglassFrameInventoryReportResponse } from './eyeglass-frame-inventory-report-response';

// @ts-ignore
import { EyeglassFrameSalesReportResponse } from './eyeglass-frame-sales-report-response';

// @ts-ignore
import { EyeglassLensMeasurementResponse } from './eyeglass-lens-measurement-response';

// @ts-ignore
import { EyeglassLensOrderDetailResponse } from './eyeglass-lens-order-detail-response';

// @ts-ignore
import { EyeglassOrderResponse } from './eyeglass-order-response';

// @ts-ignore
import { EyeglassOrderSearchResponse } from './eyeglass-order-search-response';

// @ts-ignore
import { EyeglassPrescriptionEyeRequest } from './eyeglass-prescription-eye-request';

// @ts-ignore
import { EyeglassPrescriptionEyeResponse } from './eyeglass-prescription-eye-response';

// @ts-ignore
import { EyeglassPrescriptionListResponse } from './eyeglass-prescription-list-response';

// @ts-ignore
import { EyeglassPrescriptionResponse } from './eyeglass-prescription-response';

// @ts-ignore
import { FamilyAccountMemberDetailResponse } from './family-account-member-detail-response';

// @ts-ignore
import { FamilyAccountMembersDetailResponse } from './family-account-members-detail-response';

// @ts-ignore
import { FamilyCreditRequest } from './family-credit-request';

// @ts-ignore
import { FamilyDiffResponse } from './family-diff-response';

// @ts-ignore
import { FamilyHealthHistoryResponse } from './family-health-history-response';

// @ts-ignore
import { FamilyHealthHistoryReviewSetResponse } from './family-health-history-review-set-response';

// @ts-ignore
import { FamilyHistoryRequest } from './family-history-request';

// @ts-ignore
import { FamilyHistoryResponse } from './family-history-response';

// @ts-ignore
import { FamilyMemberContactInformationRequest } from './family-member-contact-information-request';

// @ts-ignore
import { FamilyRefundRequest } from './family-refund-request';

// @ts-ignore
import { FeatureFlagDetailResponse } from './feature-flag-detail-response';

// @ts-ignore
import { FeatureFlagEmployeeSummaryResponse } from './feature-flag-employee-summary-response';

// @ts-ignore
import { FeatureFlagSummaryResponse } from './feature-flag-summary-response';

// @ts-ignore
import { FeeScheduleDropdownResponse } from './fee-schedule-dropdown-response';

// @ts-ignore
import { FeeScheduleItemProductResponse } from './fee-schedule-item-product-response';

// @ts-ignore
import { FeeScheduleItemServiceCptResponse } from './fee-schedule-item-service-cpt-response';

// @ts-ignore
import { FeeScheduleItemServiceOtherResponse } from './fee-schedule-item-service-other-response';

// @ts-ignore
import { FeeScheduleListResponse } from './fee-schedule-list-response';

// @ts-ignore
import { FeeScheduleRangeResponse } from './fee-schedule-range-response';

// @ts-ignore
import { FeeScheduleResponse } from './fee-schedule-response';

// @ts-ignore
import { FieldDefinitionFamilyHistoryResponse } from './field-definition-family-history-response';

// @ts-ignore
import { FieldDefinitionHistoryOfPresentIllnessResponse } from './field-definition-history-of-present-illness-response';

// @ts-ignore
import { FieldDefinitionResponse } from './field-definition-response';

// @ts-ignore
import { FieldDefinitionReviewOfSystemsResponse } from './field-definition-review-of-systems-response';

// @ts-ignore
import { FieldOptionResponse } from './field-option-response';

// @ts-ignore
import { FieldValueBooleanRequest } from './field-value-boolean-request';

// @ts-ignore
import { FieldValueBooleanResponse } from './field-value-boolean-response';

// @ts-ignore
import { FieldValueDateRequest } from './field-value-date-request';

// @ts-ignore
import { FieldValueDateResponse } from './field-value-date-response';

// @ts-ignore
import { FieldValueDecimalRequest } from './field-value-decimal-request';

// @ts-ignore
import { FieldValueDecimalResponse } from './field-value-decimal-response';

// @ts-ignore
import { FieldValueLargeStringRequest } from './field-value-large-string-request';

// @ts-ignore
import { FieldValueLargeStringResponse } from './field-value-large-string-response';

// @ts-ignore
import { FieldValueMultiSelectionDiffResponse } from './field-value-multi-selection-diff-response';

// @ts-ignore
import { FieldValueMultiSelectionRequest } from './field-value-multi-selection-request';

// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

// @ts-ignore
import { FieldValueNumberRequest } from './field-value-number-request';

// @ts-ignore
import { FieldValueNumberResponse } from './field-value-number-response';

// @ts-ignore
import { FieldValueRequest } from './field-value-request';

// @ts-ignore
import { FieldValueResponse } from './field-value-response';

// @ts-ignore
import { FieldValueSelectionRequest } from './field-value-selection-request';

// @ts-ignore
import { FieldValueSelectionResponse } from './field-value-selection-response';

// @ts-ignore
import { FieldValueSmallStringRequest } from './field-value-small-string-request';

// @ts-ignore
import { FieldValueSmallStringResponse } from './field-value-small-string-response';

// @ts-ignore
import { FileFolderResponse } from './file-folder-response';

// @ts-ignore
import { FileInfoResponse } from './file-info-response';

// @ts-ignore
import { FinanceChargePlanNameRequest } from './finance-charge-plan-name-request';

// @ts-ignore
import { FinanceChargePlanNameResponse } from './finance-charge-plan-name-response';

// @ts-ignore
import { FinanceChargePlanPeriodResponse } from './finance-charge-plan-period-response';

// @ts-ignore
import { FinanceChargePlanResponse } from './finance-charge-plan-response';

// @ts-ignore
import { FindActiveLocationProductsRequest } from './find-active-location-products-request';

// @ts-ignore
import { FindActivePracticeDiagnosisForAssessmentRequest } from './find-active-practice-diagnosis-for-assessment-request';

// @ts-ignore
import { FindAnonymousPaymentInvoicesRequest } from './find-anonymous-payment-invoices-request';

// @ts-ignore
import { FindCampaignsForPracticeRequest } from './find-campaigns-for-practice-request';

// @ts-ignore
import { FindCarePlanItemsByCategoryRequest } from './find-care-plan-items-by-category-request';

// @ts-ignore
import { FindCategoriesRequest } from './find-categories-request';

// @ts-ignore
import { FindCollectionsPaymentInvoicesRequest } from './find-collections-payment-invoices-request';

// @ts-ignore
import { FindDynamicTestHistoryRequest } from './find-dynamic-test-history-request';

// @ts-ignore
import { FindEyefinityOrderVendorsRequest } from './find-eyefinity-order-vendors-request';

// @ts-ignore
import { FindFamilyHealthHistoryReviewSetListForPatientEncounterRequest } from './find-family-health-history-review-set-list-for-patient-encounter-request';

// @ts-ignore
import { FindFamilyHealthHistoryReviewSetListForPatientRequest } from './find-family-health-history-review-set-list-for-patient-request';

// @ts-ignore
import { FindInsurancePaymentInvoicesRequest } from './find-insurance-payment-invoices-request';

// @ts-ignore
import { FindManualVendorsRequest } from './find-manual-vendors-request';

// @ts-ignore
import { FindNonPagedConnectCampaignMessagesRequest } from './find-non-paged-connect-campaign-messages-request';

// @ts-ignore
import { FindNotesByParentEntityRequest } from './find-notes-by-parent-entity-request';

// @ts-ignore
import { FindOrderProcessorsRequest } from './find-order-processors-request';

// @ts-ignore
import { FindPagedConnectCampaignMessagesRequest } from './find-paged-connect-campaign-messages-request';

// @ts-ignore
import { FindPatientPaymentInvoicesRequest } from './find-patient-payment-invoices-request';

// @ts-ignore
import { FindPracticeDiagnosesRequest } from './find-practice-diagnoses-request';

// @ts-ignore
import { FindQueriesRequest } from './find-queries-request';

// @ts-ignore
import { FindQueryTriggersRequest } from './find-query-triggers-request';

// @ts-ignore
import { FindRelatedCategoriesRequest } from './find-related-categories-request';

// @ts-ignore
import { FindScheduleItemRequest } from './find-schedule-item-request';

// @ts-ignore
import { FindScheduleItemsByIdRequest } from './find-schedule-items-by-id-request';

// @ts-ignore
import { FindSmartflowOrderVendorsRequest } from './find-smartflow-order-vendors-request';

// @ts-ignore
import { FindTodaysPatientsAppointmentsForProviderRequest } from './find-todays-patients-appointments-for-provider-request';

// @ts-ignore
import { FindTwoWayTextConversationMessageUpdatesRequest } from './find-two-way-text-conversation-message-updates-request';

// @ts-ignore
import { FindTwoWayTextConversationUpdatesRequest } from './find-two-way-text-conversation-updates-request';

// @ts-ignore
import { FindUnassignedDeviceMeasurementsRequest } from './find-unassigned-device-measurements-request';

// @ts-ignore
import { FindVCodeRequest } from './find-v-code-request';

// @ts-ignore
import { FindWorkflowScreenRequest } from './find-workflow-screen-request';

// @ts-ignore
import { FindWorkflowScreensRequest } from './find-workflow-screens-request';

// @ts-ignore
import { FormComponentPositionResponse } from './form-component-position-response';

// @ts-ignore
import { FormComponentResponse } from './form-component-response';

// @ts-ignore
import { FormFieldResponse } from './form-field-response';

// @ts-ignore
import { FormFieldSelectionResponse } from './form-field-selection-response';

// @ts-ignore
import { FormFieldStepperResponse } from './form-field-stepper-response';

// @ts-ignore
import { FormLabelResponse } from './form-label-response';

// @ts-ignore
import { FormPositionResponse } from './form-position-response';

// @ts-ignore
import { ForwardDataResponse } from './forward-data-response';

// @ts-ignore
import { FrameColorResponse } from './frame-color-response';

// @ts-ignore
import { FrameOrderResponse } from './frame-order-response';

// @ts-ignore
import { FrameSizeResponse } from './frame-size-response';

// @ts-ignore
import { FramesDataSubscriptionResponse } from './frames-data-subscription-response';

// @ts-ignore
import { GenerateClinicalSummaryRequest } from './generate-clinical-summary-request';

// @ts-ignore
import { GetLatestFamilyHealthHistoryForPatientRequest } from './get-latest-family-health-history-for-patient-request';

// @ts-ignore
import { GetPracticeLaunchUrlRequest } from './get-practice-launch-url-request';

// @ts-ignore
import { GlaucomaCareMedicationPrescriptionResponse } from './glaucoma-care-medication-prescription-response';

// @ts-ignore
import { GlaucomaCareResponse } from './glaucoma-care-response';

// @ts-ignore
import { GlaucomaCareServiceHistoryResponse } from './glaucoma-care-service-history-response';

// @ts-ignore
import { GlaucomaDataResponse } from './glaucoma-data-response';

// @ts-ignore
import { GrantCreditsRequest } from './grant-credits-request';

// @ts-ignore
import { GuarantorNameResponse } from './guarantor-name-response';

// @ts-ignore
import { HistoryDiagnosisCarePlanItemResponse } from './history-diagnosis-care-plan-item-response';

// @ts-ignore
import { Hl7ValueSetResponse } from './hl7-value-set-response';

// @ts-ignore
import { HumanReadableInsuranceRemittanceResponse } from './human-readable-insurance-remittance-response';

// @ts-ignore
import { ImportMasterQueriesRequest } from './import-master-queries-request';

// @ts-ignore
import { ImportResultResponse } from './import-result-response';

// @ts-ignore
import { IncorporateDrugAllergyRequest } from './incorporate-drug-allergy-request';

// @ts-ignore
import { IncorporateDrugAllergyUpdateRequest } from './incorporate-drug-allergy-update-request';

// @ts-ignore
import { IncorporateExternalDataRequest } from './incorporate-external-data-request';

// @ts-ignore
import { IncorporateMedicationPrescriptionRequest } from './incorporate-medication-prescription-request';

// @ts-ignore
import { IncorporatePersonDiagnosisRequest } from './incorporate-person-diagnosis-request';

// @ts-ignore
import { InsuranceClaimProcessorResponse } from './insurance-claim-processor-response';

// @ts-ignore
import { InsuranceClaimStatusResponse } from './insurance-claim-status-response';

// @ts-ignore
import { InsuranceCoveragesResponse } from './insurance-coverages-response';

// @ts-ignore
import { InsuranceInvoiceTransferSplitRequest } from './insurance-invoice-transfer-split-request';

// @ts-ignore
import { InsuranceRefundRequest } from './insurance-refund-request';

// @ts-ignore
import { IntakeAllergyListResponse } from './intake-allergy-list-response';

// @ts-ignore
import { IntakeAllergyResponse } from './intake-allergy-response';

// @ts-ignore
import { IntakeInsuranceListResponse } from './intake-insurance-list-response';

// @ts-ignore
import { IntakeInsuranceResponse } from './intake-insurance-response';

// @ts-ignore
import { IntakeMedicationListResponse } from './intake-medication-list-response';

// @ts-ignore
import { IntakeMedicationResponse } from './intake-medication-response';

// @ts-ignore
import { InterviewHistoryResponse } from './interview-history-response';

// @ts-ignore
import { InterviewResponse } from './interview-response';

// @ts-ignore
import { InterviewTemplateDetailResponse } from './interview-template-detail-response';

// @ts-ignore
import { InterviewTemplateResponse } from './interview-template-response';

// @ts-ignore
import { InterviewTemplateSummaryResponse } from './interview-template-summary-response';

// @ts-ignore
import { InventoryServiceResponse } from './inventory-service-response';

// @ts-ignore
import { InvoiceAppointmentResponse } from './invoice-appointment-response';

// @ts-ignore
import { InvoiceDashboardResponse } from './invoice-dashboard-response';

// @ts-ignore
import { InvoiceDashboardSearchRequest } from './invoice-dashboard-search-request';

// @ts-ignore
import { InvoiceDateResponse } from './invoice-date-response';

// @ts-ignore
import { InvoiceDiagnosesRequest } from './invoice-diagnoses-request';

// @ts-ignore
import { InvoiceInsuranceClaimResponse } from './invoice-insurance-claim-response';

// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemDetailResponse } from './invoice-item-detail-response';

// @ts-ignore
import { InvoiceItemDiagnosisResponse } from './invoice-item-diagnosis-response';

// @ts-ignore
import { InvoiceItemListResponse } from './invoice-item-list-response';

// @ts-ignore
import { InvoiceItemModifierResponse } from './invoice-item-modifier-response';

// @ts-ignore
import { InvoiceItemPaymentRequest } from './invoice-item-payment-request';

// @ts-ignore
import { InvoiceItemPaymentResponse } from './invoice-item-payment-response';

// @ts-ignore
import { InvoiceItemPersonDiagnosisResponse } from './invoice-item-person-diagnosis-response';

// @ts-ignore
import { InvoiceItemPracticeDiagnosisResponse } from './invoice-item-practice-diagnosis-response';

// @ts-ignore
import { InvoiceItemResponse } from './invoice-item-response';

// @ts-ignore
import { InvoiceItemTaxResponse } from './invoice-item-tax-response';

// @ts-ignore
import { InvoiceResponse } from './invoice-response';

// @ts-ignore
import { InvoiceTransferRequest } from './invoice-transfer-request';

// @ts-ignore
import { InvoiceTransferResponse } from './invoice-transfer-response';

// @ts-ignore
import { InvoicesUpdatedResponse } from './invoices-updated-response';

// @ts-ignore
import { IopReadingResponse } from './iop-reading-response';

// @ts-ignore
import { IsPayOnlineUrlPathValidRequest } from './is-pay-online-url-path-valid-request';

// @ts-ignore
import { ItemQuantityChangeResponse } from './item-quantity-change-response';

// @ts-ignore
import { KeratometryTestResponse } from './keratometry-test-response';

// @ts-ignore
import { LabOrderListResponse } from './lab-order-list-response';

// @ts-ignore
import { LabOrderResponse } from './lab-order-response';

// @ts-ignore
import { LabVendorResponse } from './lab-vendor-response';

// @ts-ignore
import { LaunchRxntResponse } from './launch-rxnt-response';

// @ts-ignore
import { LedgerPostingReportRequest } from './ledger-posting-report-request';

// @ts-ignore
import { LedgerPostingReportResponse } from './ledger-posting-report-response';

// @ts-ignore
import { LocationLevelPrescriptionExpirationRequest } from './location-level-prescription-expiration-request';

// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForBarcodePrintingResponse } from './location-product-item-for-barcode-printing-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { LocationProductItemTableResponse } from './location-product-item-table-response';

// @ts-ignore
import { LocationProductTableResponse } from './location-product-table-response';

// @ts-ignore
import { LocationSelectRequest } from './location-select-request';

// @ts-ignore
import { LocationTaxResponse } from './location-tax-response';

// @ts-ignore
import { LoginUsingExistingSessionRequest } from './login-using-existing-session-request';

// @ts-ignore
import { LoincCodeRequest } from './loinc-code-request';

// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

// @ts-ignore
import { ManualProcessPaymentRequest } from './manual-process-payment-request';

// @ts-ignore
import { ManufacturerForPatientFrameResponse } from './manufacturer-for-patient-frame-response';

// @ts-ignore
import { ManufacturerResponse } from './manufacturer-response';

// @ts-ignore
import { ManufacturerSummaryResponse } from './manufacturer-summary-response';

// @ts-ignore
import { MappedTestFieldRequest } from './mapped-test-field-request';

// @ts-ignore
import { MappedTestRequest } from './mapped-test-request';

// @ts-ignore
import { MarkClaimAsSubmittedRequest } from './mark-claim-as-submitted-request';

// @ts-ignore
import { MarkClaimsAsCancelledRequest } from './mark-claims-as-cancelled-request';

// @ts-ignore
import { MarkMessageParticipantPersonsNewRequest } from './mark-message-participant-persons-new-request';

// @ts-ignore
import { MasterBaudRateResponse } from './master-baud-rate-response';

// @ts-ignore
import { MasterDeviceResponse } from './master-device-response';

// @ts-ignore
import { MasterFileDeviceResponse } from './master-file-device-response';

// @ts-ignore
import { MasterQueryResponse } from './master-query-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { MasterReferenceEthnicityDataResponse } from './master-reference-ethnicity-data-response';

// @ts-ignore
import { MasterReferenceLanguageDataResponse } from './master-reference-language-data-response';

// @ts-ignore
import { MasterReferenceRaceDataResponse } from './master-reference-race-data-response';

// @ts-ignore
import { MasterSerialDeviceResponse } from './master-serial-device-response';

// @ts-ignore
import { MeaningfulUseReportRequest } from './meaningful-use-report-request';

// @ts-ignore
import { MeaningfulUseReportResponse } from './meaningful-use-report-response';

// @ts-ignore
import { MedicalDecisionMakingResponse } from './medical-decision-making-response';

// @ts-ignore
import { MedicationPrescriptionImportResponse } from './medication-prescription-import-response';

// @ts-ignore
import { MedicationPrescriptionListResponse } from './medication-prescription-list-response';

// @ts-ignore
import { MedicationPrescriptionRefillRequest } from './medication-prescription-refill-request';

// @ts-ignore
import { MedicationPrescriptionRequest } from './medication-prescription-request';

// @ts-ignore
import { MedicationPrescriptionResponse } from './medication-prescription-response';

// @ts-ignore
import { MedicationReviewHistoryRequest } from './medication-review-history-request';

// @ts-ignore
import { MedicationReviewHistoryResponse } from './medication-review-history-response';

// @ts-ignore
import { MentalStatusResponse } from './mental-status-response';

// @ts-ignore
import { MergePatientsRequest } from './merge-patients-request';

// @ts-ignore
import { MessageDetailResponse } from './message-detail-response';

// @ts-ignore
import { MessageFolderResponse } from './message-folder-response';

// @ts-ignore
import { MessageParticipantNameResponse } from './message-participant-name-response';

// @ts-ignore
import { MessageParticipantResponse } from './message-participant-response';

// @ts-ignore
import { MessagingDirectProviderResponse } from './messaging-direct-provider-response';

// @ts-ignore
import { MinMaxInventoryReportResponse } from './min-max-inventory-report-response';

// @ts-ignore
import { MipsQualityReportRequest } from './mips-quality-report-request';

// @ts-ignore
import { MipsQualityReportResponse } from './mips-quality-report-response';

// @ts-ignore
import { MipsQualityResultResponse } from './mips-quality-result-response';

// @ts-ignore
import { ModifierResponse } from './modifier-response';

// @ts-ignore
import { MoveFolderRequest } from './move-folder-request';

// @ts-ignore
import { MovePatientFileRequest } from './move-patient-file-request';

// @ts-ignore
import { NoteResponse } from './note-response';

// @ts-ignore
import { OcularDiffResponse } from './ocular-diff-response';

// @ts-ignore
import { OcularHistoryRequest } from './ocular-history-request';

// @ts-ignore
import { OcularHistoryResponse } from './ocular-history-response';

// @ts-ignore
import { OdOsLocationProductIdsActiveResponse } from './od-os-location-product-ids-active-response';

// @ts-ignore
import { OfficeClosedCalendarResponse } from './office-closed-calendar-response';

// @ts-ignore
import { OfficeClosedResponse } from './office-closed-response';

// @ts-ignore
import { OfficeClosedSidebarResponse } from './office-closed-sidebar-response';

// @ts-ignore
import { OpenEdgeCardReaderResponse } from './open-edge-card-reader-response';

// @ts-ignore
import { OpenEdgeConfigurationResponse } from './open-edge-configuration-response';

// @ts-ignore
import { OpenEdgeCreatePayFieldsTransactionRequest } from './open-edge-create-pay-fields-transaction-request';

// @ts-ignore
import { OpenEdgeCreateSavedCardRequest } from './open-edge-create-saved-card-request';

// @ts-ignore
import { OpenEdgeCreateStoredCardTransactionRequest } from './open-edge-create-stored-card-transaction-request';

// @ts-ignore
import { OpenEdgeFinishTransactionRequest } from './open-edge-finish-transaction-request';

// @ts-ignore
import { OpenEdgePaymentTransactionResponse } from './open-edge-payment-transaction-response';

// @ts-ignore
import { OpenEdgePracticeLocationResponse } from './open-edge-practice-location-response';

// @ts-ignore
import { OpenEdgeSaveCardResponse } from './open-edge-save-card-response';

// @ts-ignore
import { OpenEdgeSettingsRequest } from './open-edge-settings-request';

// @ts-ignore
import { OpenEdgeSettingsResponse } from './open-edge-settings-response';

// @ts-ignore
import { OpenEdgeSetupTransportKeysRequest } from './open-edge-setup-transport-keys-request';

// @ts-ignore
import { OpenEdgeTransactionDetailsResponse } from './open-edge-transaction-details-response';

// @ts-ignore
import { OpenEdgeTransportKeysResponse } from './open-edge-transport-keys-response';

// @ts-ignore
import { OpenEdgeVoidTransactionResponse } from './open-edge-void-transaction-response';

// @ts-ignore
import { OpenOrderResponse } from './open-order-response';

// @ts-ignore
import { OpticalDeviceLensMeasurementResponse } from './optical-device-lens-measurement-response';

// @ts-ignore
import { OpticalDeviceMeasurementResponse } from './optical-device-measurement-response';

// @ts-ignore
import { OpticalOrderContactLensDetailResponse } from './optical-order-contact-lens-detail-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './optical-order-detail-response';

// @ts-ignore
import { OpticalOrderEyeglassDetailResponse } from './optical-order-eyeglass-detail-response';

// @ts-ignore
import { OpticalOrderPodContactLensResponse } from './optical-order-pod-contact-lens-response';

// @ts-ignore
import { OpticalOrderPodEyeglassResponse } from './optical-order-pod-eyeglass-response';

// @ts-ignore
import { OpticalOrderPodFrameResponse } from './optical-order-pod-frame-response';

// @ts-ignore
import { OpticalOrderPodOrderResponse } from './optical-order-pod-order-response';

// @ts-ignore
import { OpticalOrderPodOrderSearchRequest } from './optical-order-pod-order-search-request';

// @ts-ignore
import { OpticalSummaryEncounterHistoryResponse } from './optical-summary-encounter-history-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsContactLensResponse } from './optical-summary-latest-findings-contact-lens-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsContactLensTrialResponse } from './optical-summary-latest-findings-contact-lens-trial-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsEyeglassResponse } from './optical-summary-latest-findings-eyeglass-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsRefractionResponse } from './optical-summary-latest-findings-refraction-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsResponse } from './optical-summary-latest-findings-response';

// @ts-ignore
import { OrderContactLensPackagingResponse } from './order-contact-lens-packaging-response';

// @ts-ignore
import { OrderContactLensPrescriptionResponse } from './order-contact-lens-prescription-response';

// @ts-ignore
import { OrderContactLensTrialResponse } from './order-contact-lens-trial-response';

// @ts-ignore
import { OrderCountsByStatusResponse } from './order-counts-by-status-response';

// @ts-ignore
import { OrderDashboardSortRequest } from './order-dashboard-sort-request';

// @ts-ignore
import { OrderEyefinityFeeLensResponse } from './order-eyefinity-fee-lens-response';

// @ts-ignore
import { OrderEyefinityFeeMappingResponse } from './order-eyefinity-fee-mapping-response';

// @ts-ignore
import { OrderEyefinityFeeOptionResponse } from './order-eyefinity-fee-option-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleListResponse } from './order-eyefinity-fee-schedule-list-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleResponse } from './order-eyefinity-fee-schedule-response';

// @ts-ignore
import { OrderEyefinityFeeServiceResponse } from './order-eyefinity-fee-service-response';

// @ts-ignore
import { OrderEyeglassPrescriptionResponse } from './order-eyeglass-prescription-response';

// @ts-ignore
import { OrderForPrintResponse } from './order-for-print-response';

// @ts-ignore
import { OrderOnHoldHistoryResponse } from './order-on-hold-history-response';

// @ts-ignore
import { OrderPerformedServiceResponse } from './order-performed-service-response';

// @ts-ignore
import { OrderPrescriptionResponse } from './order-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderProductColorResponse } from './order-product-color-response';

// @ts-ignore
import { OrderProductOptionRequest } from './order-product-option-request';

// @ts-ignore
import { OrderProductOptionResponse } from './order-product-option-response';

// @ts-ignore
import { OrderQueryResponse } from './order-query-response';

// @ts-ignore
import { OrderResponse } from './order-response';

// @ts-ignore
import { OrderStatusHistoryResponse } from './order-status-history-response';

// @ts-ignore
import { OrderStatusResponse } from './order-status-response';

// @ts-ignore
import { OrderStatusUpdateRequest } from './order-status-update-request';

// @ts-ignore
import { OrderTwelve84ConfigurationOptionResponse } from './order-twelve84-configuration-option-response';

// @ts-ignore
import { OrderTwelve84ConfigurationResponse } from './order-twelve84-configuration-response';

// @ts-ignore
import { OrderTwelve84FrameResponse } from './order-twelve84-frame-response';

// @ts-ignore
import { OrderTwelve84Response } from './order-twelve84-response';

// @ts-ignore
import { OrderVendorConfigResponse } from './order-vendor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { OrdersAdvancedSearchRequest } from './orders-advanced-search-request';

// @ts-ignore
import { OrdersSearchResponse } from './orders-search-response';

// @ts-ignore
import { OrdersSimpleSearchRequest } from './orders-simple-search-request';

// @ts-ignore
import { OtherAllergyResponse } from './other-allergy-response';

// @ts-ignore
import { PagingAndSortingRequest } from './paging-and-sorting-request';

// @ts-ignore
import { ParseExternalPatientDataFileRequest } from './parse-external-patient-data-file-request';

// @ts-ignore
import { PatientAccountBalancesResponse } from './patient-account-balances-response';

// @ts-ignore
import { PatientAccountSummaryResponse } from './patient-account-summary-response';

// @ts-ignore
import { PatientAddWorkflowScreenToWorkflowStepRequest } from './patient-add-workflow-screen-to-workflow-step-request';

// @ts-ignore
import { PatientAdditionalInformationRequest } from './patient-additional-information-request';

// @ts-ignore
import { PatientBasicInformationRequest } from './patient-basic-information-request';

// @ts-ignore
import { PatientBirthdaySearchRequest } from './patient-birthday-search-request';

// @ts-ignore
import { PatientBirthdaySearchResponse } from './patient-birthday-search-response';

// @ts-ignore
import { PatientCommentResponse } from './patient-comment-response';

// @ts-ignore
import { PatientCommunicationEligibilityResponse } from './patient-communication-eligibility-response';

// @ts-ignore
import { PatientCommunicationPreferencesRequest } from './patient-communication-preferences-request';

// @ts-ignore
import { PatientCommunicationPreferencesResponse } from './patient-communication-preferences-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientConsentResponse } from './patient-consent-response';

// @ts-ignore
import { PatientContactEmergencyContactStatusRequest } from './patient-contact-emergency-contact-status-request';

// @ts-ignore
import { PatientContactInformationResponse } from './patient-contact-information-response';

// @ts-ignore
import { PatientCreditRequest } from './patient-credit-request';

// @ts-ignore
import { PatientCvxCodeResponse } from './patient-cvx-code-response';

// @ts-ignore
import { PatientDashboardSummaryResponse } from './patient-dashboard-summary-response';

// @ts-ignore
import { PatientDataExportResponse } from './patient-data-export-response';

// @ts-ignore
import { PatientDocumentContentRequest } from './patient-document-content-request';

// @ts-ignore
import { PatientDocumentResponse } from './patient-document-response';

// @ts-ignore
import { PatientDocumentSummaryResponse } from './patient-document-summary-response';

// @ts-ignore
import { PatientDuplicateSearchRequest } from './patient-duplicate-search-request';

// @ts-ignore
import { PatientDuplicateSearchResponse } from './patient-duplicate-search-response';

// @ts-ignore
import { PatientEmergencyContactResponse } from './patient-emergency-contact-response';

// @ts-ignore
import { PatientEmploymentRequest } from './patient-employment-request';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientFamilyInformationResponse } from './patient-family-information-response';

// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

// @ts-ignore
import { PatientFileListRequest } from './patient-file-list-request';

// @ts-ignore
import { PatientFileListResponse } from './patient-file-list-response';

// @ts-ignore
import { PatientGuarantorResponse } from './patient-guarantor-response';

// @ts-ignore
import { PatientHealthConcernRequest } from './patient-health-concern-request';

// @ts-ignore
import { PatientHealthConcernResponse } from './patient-health-concern-response';

// @ts-ignore
import { PatientHealthGoalResponse } from './patient-health-goal-response';

// @ts-ignore
import { PatientHeuristicSearchResponse } from './patient-heuristic-search-response';

// @ts-ignore
import { PatientHl7ValueSetResponse } from './patient-hl7-value-set-response';

// @ts-ignore
import { PatientHl7ValueSetSearchRequest } from './patient-hl7-value-set-search-request';

// @ts-ignore
import { PatientImplantableDeviceResponse } from './patient-implantable-device-response';

// @ts-ignore
import { PatientInvoiceDashboardSearchRequest } from './patient-invoice-dashboard-search-request';

// @ts-ignore
import { PatientInvoiceTransferSplitRequest } from './patient-invoice-transfer-split-request';

// @ts-ignore
import { PatientManagementSearchRequest } from './patient-management-search-request';

// @ts-ignore
import { PatientMinerSearchRequest } from './patient-miner-search-request';

// @ts-ignore
import { PatientMinerSearchResponse } from './patient-miner-search-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PatientOngoingCareResponse } from './patient-ongoing-care-response';

// @ts-ignore
import { PatientOrderHistoryResponse } from './patient-order-history-response';

// @ts-ignore
import { PatientOtherInformationRequest } from './patient-other-information-request';

// @ts-ignore
import { PatientPaymentResponse } from './patient-payment-response';

// @ts-ignore
import { PatientPerformedServiceResponse } from './patient-performed-service-response';

// @ts-ignore
import { PatientPharmacyResponse } from './patient-pharmacy-response';

// @ts-ignore
import { PatientPhoneInformationRequest } from './patient-phone-information-request';

// @ts-ignore
import { PatientPodConnectMessageResponse } from './patient-pod-connect-message-response';

// @ts-ignore
import { PatientPodConnectMessagesResponse } from './patient-pod-connect-messages-response';

// @ts-ignore
import { PatientPodContactLensEyeResponse } from './patient-pod-contact-lens-eye-response';

// @ts-ignore
import { PatientPodContactLensResponse } from './patient-pod-contact-lens-response';

// @ts-ignore
import { PatientPodContactResponse } from './patient-pod-contact-response';

// @ts-ignore
import { PatientPodEncounterResponse } from './patient-pod-encounter-response';

// @ts-ignore
import { PatientPodEyeglassResponse } from './patient-pod-eyeglass-response';

// @ts-ignore
import { PatientPodFamilyAccountMemberResponse } from './patient-pod-family-account-member-response';

// @ts-ignore
import { PatientPodFamilyMembersResponse } from './patient-pod-family-members-response';

// @ts-ignore
import { PatientPodLabOrderResponse } from './patient-pod-lab-order-response';

// @ts-ignore
import { PatientPodLastAndNextEncounterResponse } from './patient-pod-last-and-next-encounter-response';

// @ts-ignore
import { PatientPodLastEncounterResponse } from './patient-pod-last-encounter-response';

// @ts-ignore
import { PatientPodMedicationResponse } from './patient-pod-medication-response';

// @ts-ignore
import { PatientPodNextAppointmentResponse } from './patient-pod-next-appointment-response';

// @ts-ignore
import { PatientPodNoteResponse } from './patient-pod-note-response';

// @ts-ignore
import { PatientPodNotesResponse } from './patient-pod-notes-response';

// @ts-ignore
import { PatientPodOrderResponse } from './patient-pod-order-response';

// @ts-ignore
import { PatientPodPatientAccountResponse } from './patient-pod-patient-account-response';

// @ts-ignore
import { PatientPodPersonDiagnosisResponse } from './patient-pod-person-diagnosis-response';

// @ts-ignore
import { PatientPodPersonInsuranceResponse } from './patient-pod-person-insurance-response';

// @ts-ignore
import { PatientPodRecallResponse } from './patient-pod-recall-response';

// @ts-ignore
import { PatientPodTaskResponse } from './patient-pod-task-response';

// @ts-ignore
import { PatientPopoverResponse } from './patient-popover-response';

// @ts-ignore
import { PatientPortalAccessRequest } from './patient-portal-access-request';

// @ts-ignore
import { PatientPortalAuthorizedPatientResponse } from './patient-portal-authorized-patient-response';

// @ts-ignore
import { PatientPortionRequest } from './patient-portion-request';

// @ts-ignore
import { PatientProviderResponse } from './patient-provider-response';

// @ts-ignore
import { PatientQuickViewResponse } from './patient-quick-view-response';

// @ts-ignore
import { PatientRecallDetailResponse } from './patient-recall-detail-response';

// @ts-ignore
import { PatientRecallResponse } from './patient-recall-response';

// @ts-ignore
import { PatientRecallSearchRequest } from './patient-recall-search-request';

// @ts-ignore
import { PatientRecallSearchResponse } from './patient-recall-search-response';

// @ts-ignore
import { PatientReferralRequest } from './patient-referral-request';

// @ts-ignore
import { PatientReferralResponse } from './patient-referral-response';

// @ts-ignore
import { PatientRefundRequest } from './patient-refund-request';

// @ts-ignore
import { PatientResponse } from './patient-response';

// @ts-ignore
import { PatientSchedulingInfoResponse } from './patient-scheduling-info-response';

// @ts-ignore
import { PatientSchedulingPreferenceResponse } from './patient-scheduling-preference-response';

// @ts-ignore
import { PatientSearchByIdsRequest } from './patient-search-by-ids-request';

// @ts-ignore
import { PatientSearchRequest } from './patient-search-request';

// @ts-ignore
import { PatientSearchResponse } from './patient-search-response';

// @ts-ignore
import { PatientSearchStringRequest } from './patient-search-string-request';

// @ts-ignore
import { PatientSimpleSearchRequest } from './patient-simple-search-request';

// @ts-ignore
import { PatientTargetIopResponse } from './patient-target-iop-response';

// @ts-ignore
import { PatientTaskResponse } from './patient-task-response';

// @ts-ignore
import { PatientTriggerOutcomeResponse } from './patient-trigger-outcome-response';

// @ts-ignore
import { PatientVaccinationListResponse } from './patient-vaccination-list-response';

// @ts-ignore
import { PatientVaccinationRequest } from './patient-vaccination-request';

// @ts-ignore
import { PatientVaccinationResponse } from './patient-vaccination-response';

// @ts-ignore
import { PatientVaccineManufacturerResponse } from './patient-vaccine-manufacturer-response';

// @ts-ignore
import { PatientWorkflowMenuResponse } from './patient-workflow-menu-response';

// @ts-ignore
import { PatientWorkflowScreenMenuResponse } from './patient-workflow-screen-menu-response';

// @ts-ignore
import { PatientWorkflowScreenTemplateListResponse } from './patient-workflow-screen-template-list-response';

// @ts-ignore
import { PatientWorkflowStepMenuResponse } from './patient-workflow-step-menu-response';

// @ts-ignore
import { PatientWorkflowTestTemplateListResponse } from './patient-workflow-test-template-list-response';

// @ts-ignore
import { PaymentDashboardResponse } from './payment-dashboard-response';

// @ts-ignore
import { PaymentDashboardSearchRequest } from './payment-dashboard-search-request';

// @ts-ignore
import { PaymentGroupResponse } from './payment-group-response';

// @ts-ignore
import { PaymentInfoResponse } from './payment-info-response';

// @ts-ignore
import { PaymentInvoiceListResponse } from './payment-invoice-list-response';

// @ts-ignore
import { PaymentManualInvoiceSearchRequest } from './payment-manual-invoice-search-request';

// @ts-ignore
import { PaymentPreferencesResponse } from './payment-preferences-response';

// @ts-ignore
import { PaymentProcessorPracticeLocationResponse } from './payment-processor-practice-location-response';

// @ts-ignore
import { PaymentResponse } from './payment-response';

// @ts-ignore
import { PaymentStoredTokenResponse } from './payment-stored-token-response';

// @ts-ignore
import { PaymentTransactionReceiptResponse } from './payment-transaction-receipt-response';

// @ts-ignore
import { PaymentTransactionResponse } from './payment-transaction-response';

// @ts-ignore
import { PaymentTransferInvoiceItemsResponse } from './payment-transfer-invoice-items-response';

// @ts-ignore
import { PerformedServiceResponse } from './performed-service-response';

// @ts-ignore
import { PersonCoverageResponse } from './person-coverage-response';

// @ts-ignore
import { PersonDetailsRequest } from './person-details-request';

// @ts-ignore
import { PersonDetailsResponse } from './person-details-response';

// @ts-ignore
import { PersonDiagnosisImportResponse } from './person-diagnosis-import-response';

// @ts-ignore
import { PersonDiagnosisResponse } from './person-diagnosis-response';

// @ts-ignore
import { PersonDiagnosisWithCarePlansResponse } from './person-diagnosis-with-care-plans-response';

// @ts-ignore
import { PersonGeneralHistoryResponse } from './person-general-history-response';

// @ts-ignore
import { PersonInsuranceCompanyResponse } from './person-insurance-company-response';

// @ts-ignore
import { PersonInsuranceDetailsResponse } from './person-insurance-details-response';

// @ts-ignore
import { PersonInsuranceDropdownResponse } from './person-insurance-dropdown-response';

// @ts-ignore
import { PersonInsurancePolicyResponse } from './person-insurance-policy-response';

// @ts-ignore
import { PersonInsuranceResponse } from './person-insurance-response';

// @ts-ignore
import { PersonInsuranceSummaryResponse } from './person-insurance-summary-response';

// @ts-ignore
import { PersonInsuranceTableResponse } from './person-insurance-table-response';

// @ts-ignore
import { PersonInsuranceTooltipResponse } from './person-insurance-tooltip-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PfshRequest } from './pfsh-request';

// @ts-ignore
import { PfshResponse } from './pfsh-response';

// @ts-ignore
import { PharmacyBrandResponse } from './pharmacy-brand-response';

// @ts-ignore
import { PharmacyResponse } from './pharmacy-response';

// @ts-ignore
import { PharmacySummaryResponse } from './pharmacy-summary-response';

// @ts-ignore
import { PhoneInformationRequest } from './phone-information-request';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PostalCodeSearchRequest } from './postal-code-search-request';

// @ts-ignore
import { PqriAlertRequest } from './pqri-alert-request';

// @ts-ignore
import { PqriAlertResponse } from './pqri-alert-response';

// @ts-ignore
import { PracticeAndLocationsIdentifiersResponse } from './practice-and-locations-identifiers-response';

// @ts-ignore
import { PracticeConsentFormResponse } from './practice-consent-form-response';

// @ts-ignore
import { PracticeDiagnosisListResponse } from './practice-diagnosis-list-response';

// @ts-ignore
import { PracticeDiagnosisResponse } from './practice-diagnosis-response';

// @ts-ignore
import { PracticeDrugListResponse } from './practice-drug-list-response';

// @ts-ignore
import { PracticeDrugResponse } from './practice-drug-response';

// @ts-ignore
import { PracticeFamilyHealthHistoryFilterListResponse } from './practice-family-health-history-filter-list-response';

// @ts-ignore
import { PracticeFamilyHealthHistoryFilterRequest } from './practice-family-health-history-filter-request';

// @ts-ignore
import { PracticeFamilyHistoryFiltersSearchRequest } from './practice-family-history-filters-search-request';

// @ts-ignore
import { PracticeFileInfoResponse } from './practice-file-info-response';

// @ts-ignore
import { PracticeFileRequest } from './practice-file-request';

// @ts-ignore
import { PracticeFileResponse } from './practice-file-response';

// @ts-ignore
import { PracticeGeneralInfoResponse } from './practice-general-info-response';

// @ts-ignore
import { PracticeIdentifiersResponse } from './practice-identifiers-response';

// @ts-ignore
import { PracticeInsuranceCompanyDropdownResponse } from './practice-insurance-company-dropdown-response';

// @ts-ignore
import { PracticeInsuranceCompanyModalResponse } from './practice-insurance-company-modal-response';

// @ts-ignore
import { PracticeInsuranceCompanyResponse } from './practice-insurance-company-response';

// @ts-ignore
import { PracticeInsuranceCompanySummaryResponse } from './practice-insurance-company-summary-response';

// @ts-ignore
import { PracticeInsuranceRemittanceCodeAdjustmentResponse } from './practice-insurance-remittance-code-adjustment-response';

// @ts-ignore
import { PracticeLocationAccessCodeResponse } from './practice-location-access-code-response';

// @ts-ignore
import { PracticeLocationDefaultLabelPrinterResponse } from './practice-location-default-label-printer-response';

// @ts-ignore
import { PracticeLocationDeviceResponse } from './practice-location-device-response';

// @ts-ignore
import { PracticeLocationExternalAccessSummaryResponse } from './practice-location-external-access-summary-response';

// @ts-ignore
import { PracticeLocationFileDeviceResponse } from './practice-location-file-device-response';

// @ts-ignore
import { PracticeLocationIdentifiersResponse } from './practice-location-identifiers-response';

// @ts-ignore
import { PracticeLocationOfficeHoursResponse } from './practice-location-office-hours-response';

// @ts-ignore
import { PracticeLocationRevEngageSettingsResponse } from './practice-location-rev-engage-settings-response';

// @ts-ignore
import { PracticeLocationSerialDeviceResponse } from './practice-location-serial-device-response';

// @ts-ignore
import { PracticeLocationSubscriberResponse } from './practice-location-subscriber-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { PracticeLocationTaxRequest } from './practice-location-tax-request';

// @ts-ignore
import { PracticeLocationTransferTaxResponse } from './practice-location-transfer-tax-response';

// @ts-ignore
import { PracticeLocationValidDirectMessageResponse } from './practice-location-valid-direct-message-response';

// @ts-ignore
import { PracticeRemittanceCodeAdjustmentResponse } from './practice-remittance-code-adjustment-response';

// @ts-ignore
import { PracticeSmartApplicationResponse } from './practice-smart-application-response';

// @ts-ignore
import { PracticeTemplateSummaryResponse } from './practice-template-summary-response';

// @ts-ignore
import { PreferenceResponse } from './preference-response';

// @ts-ignore
import { PrescriptionSelectContactLensEyeResponse } from './prescription-select-contact-lens-eye-response';

// @ts-ignore
import { PrescriptionSelectContactLensResponse } from './prescription-select-contact-lens-response';

// @ts-ignore
import { PresentIllnessResponse } from './present-illness-response';

// @ts-ignore
import { PreviewBillableItemResponse } from './preview-billable-item-response';

// @ts-ignore
import { PreviewMappedTestsRequest } from './preview-mapped-tests-request';

// @ts-ignore
import { PreviousAddressRequest } from './previous-address-request';

// @ts-ignore
import { PreviousAddressResponse } from './previous-address-response';

// @ts-ignore
import { PrintStatementsRequest } from './print-statements-request';

// @ts-ignore
import { ProductBrandCollectionResponse } from './product-brand-collection-response';

// @ts-ignore
import { ProductBrandForPatientFrameResponse } from './product-brand-for-patient-frame-response';

// @ts-ignore
import { ProductBrandResponse } from './product-brand-response';

// @ts-ignore
import { ProductCategoryByMasterProductCategoryRequest } from './product-category-by-master-product-category-request';

// @ts-ignore
import { ProductCategoryResponse } from './product-category-response';

// @ts-ignore
import { ProductCollectionForPatientFrameResponse } from './product-collection-for-patient-frame-response';

// @ts-ignore
import { ProductColorResponse } from './product-color-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

// @ts-ignore
import { ProfessionalTimeAdjustmentResponse } from './professional-time-adjustment-response';

// @ts-ignore
import { ProfessionalTimeResponse } from './professional-time-response';

// @ts-ignore
import { ProfessionalTimeSessionResponse } from './professional-time-session-response';

// @ts-ignore
import { PropertyValuesResponse } from './property-values-response';

// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './provider-insurance-identifier-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ProviderWithLocationsResponse } from './provider-with-locations-response';

// @ts-ignore
import { PullForwardEncounterRequest } from './pull-forward-encounter-request';

// @ts-ignore
import { PupillaryDistanceHistoryResponse } from './pupillary-distance-history-response';

// @ts-ignore
import { QueryCriteriaFieldRequest } from './query-criteria-field-request';

// @ts-ignore
import { QueryCriteriaFieldResponse } from './query-criteria-field-response';

// @ts-ignore
import { QueryFieldCategoryResponse } from './query-field-category-response';

// @ts-ignore
import { QueryFieldOptionResponse } from './query-field-option-response';

// @ts-ignore
import { QueryFieldResponse } from './query-field-response';

// @ts-ignore
import { QueryResponse } from './query-response';

// @ts-ignore
import { QueryResultFieldResponse } from './query-result-field-response';

// @ts-ignore
import { QueryResultResponse } from './query-result-response';

// @ts-ignore
import { QueryResultValueResponse } from './query-result-value-response';

// @ts-ignore
import { QueryResultsResponse } from './query-results-response';

// @ts-ignore
import { QuerySelectFieldRequest } from './query-select-field-request';

// @ts-ignore
import { QuerySelectFieldResponse } from './query-select-field-response';

// @ts-ignore
import { QuerySortFieldRequest } from './query-sort-field-request';

// @ts-ignore
import { QuerySortFieldResponse } from './query-sort-field-response';

// @ts-ignore
import { QuerySummaryResponse } from './query-summary-response';

// @ts-ignore
import { QueryTemplateCategoryResponse } from './query-template-category-response';

// @ts-ignore
import { QueryTriggerCarePlanItemTemplateResponse } from './query-trigger-care-plan-item-template-response';

// @ts-ignore
import { QueryTriggerCarePlanTemplateResponse } from './query-trigger-care-plan-template-response';

// @ts-ignore
import { QueryTriggerResponse } from './query-trigger-response';

// @ts-ignore
import { QueryTriggerSourceResponse } from './query-trigger-source-response';

// @ts-ignore
import { QueryTriggerSummaryResponse } from './query-trigger-summary-response';

// @ts-ignore
import { QuickAddContactRequest } from './quick-add-contact-request';

// @ts-ignore
import { QuickAddFamilyMemberRequest } from './quick-add-family-member-request';

// @ts-ignore
import { QuickAddPatientRequest } from './quick-add-patient-request';

// @ts-ignore
import { ReasonForVisitResponse } from './reason-for-visit-response';

// @ts-ignore
import { ReceiptsReportRequest } from './receipts-report-request';

// @ts-ignore
import { ReceiptsReportResponse } from './receipts-report-response';

// @ts-ignore
import { ReceivePaymentPayerRequest } from './receive-payment-payer-request';

// @ts-ignore
import { ReceivePaymentPayerResponse } from './receive-payment-payer-response';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsRequest } from './receive-payment-transfer-invoice-items-request';

// @ts-ignore
import { ReconciliationBatchResponse } from './reconciliation-batch-response';

// @ts-ignore
import { ReconciliationItemResponse } from './reconciliation-item-response';

// @ts-ignore
import { ReconciliationReportResponse } from './reconciliation-report-response';

// @ts-ignore
import { RecurPatternDailyResponse } from './recur-pattern-daily-response';

// @ts-ignore
import { RecurPatternMonthlyResponse } from './recur-pattern-monthly-response';

// @ts-ignore
import { RecurPatternWeeklyResponse } from './recur-pattern-weekly-response';

// @ts-ignore
import { RecurPatternYearlyResponse } from './recur-pattern-yearly-response';

// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

// @ts-ignore
import { RefillContactLensPrescriptionRequest } from './refill-contact-lens-prescription-request';

// @ts-ignore
import { RefillEyeglassPrescriptionRequest } from './refill-eyeglass-prescription-request';

// @ts-ignore
import { RefractionEyeRequest } from './refraction-eye-request';

// @ts-ignore
import { RefractionEyeResponse } from './refraction-eye-response';

// @ts-ignore
import { RefractionResponse } from './refraction-response';

// @ts-ignore
import { RefractiveCareResponse } from './refractive-care-response';

// @ts-ignore
import { RefundInsuranceResponse } from './refund-insurance-response';

// @ts-ignore
import { RefundPatientResponse } from './refund-patient-response';

// @ts-ignore
import { RefundsReportRequest } from './refunds-report-request';

// @ts-ignore
import { RefundsReportResponse } from './refunds-report-response';

// @ts-ignore
import { RelatedCategoryResponse } from './related-category-response';

// @ts-ignore
import { RemakeEyefinityOrderRequest } from './remake-eyefinity-order-request';

// @ts-ignore
import { RemakeOrderRequest } from './remake-order-request';

// @ts-ignore
import { RemoveInvoiceItemRequest } from './remove-invoice-item-request';

// @ts-ignore
import { ReplyAllDataResponse } from './reply-all-data-response';

// @ts-ignore
import { ReplyDataResponse } from './reply-data-response';

// @ts-ignore
import { ResetClaimsRequest } from './reset-claims-request';

// @ts-ignore
import { ResolvePersonDiagnosisRequest } from './resolve-person-diagnosis-request';

// @ts-ignore
import { ReturnOrderRequest } from './return-order-request';

// @ts-ignore
import { RevClearConfigurationResponse } from './rev-clear-configuration-response';

// @ts-ignore
import { RevClearExternalCredentialResponse } from './rev-clear-external-credential-response';

// @ts-ignore
import { RevDirectRegisterLocationRequest } from './rev-direct-register-location-request';

// @ts-ignore
import { RevDirectResponse } from './rev-direct-response';

// @ts-ignore
import { ReviewOfSystemsDiffResponse } from './review-of-systems-diff-response';

// @ts-ignore
import { ReviewOfSystemsRequest } from './review-of-systems-request';

// @ts-ignore
import { ReviewOfSystemsResponse } from './review-of-systems-response';

// @ts-ignore
import { ReviewPatientFamilyHealthHistoryReviewSetRequest } from './review-patient-family-health-history-review-set-request';

// @ts-ignore
import { RunPatientTriggersRequest } from './run-patient-triggers-request';

// @ts-ignore
import { RunQueryRequest } from './run-query-request';

// @ts-ignore
import { RxSelectContactLensResponse } from './rx-select-contact-lens-response';

// @ts-ignore
import { RxSelectEyeglassResponse } from './rx-select-eyeglass-response';

// @ts-ignore
import { RxntCompanyMapResponse } from './rxnt-company-map-response';

// @ts-ignore
import { RxntCredentialRequest } from './rxnt-credential-request';

// @ts-ignore
import { RxntPostResponse } from './rxnt-post-response';

// @ts-ignore
import { SalesReportRequest } from './sales-report-request';

// @ts-ignore
import { SalesReportResponse } from './sales-report-response';

// @ts-ignore
import { SalesRepresentativeResponse } from './sales-representative-response';

// @ts-ignore
import { SalesRepresentativesSummaryResponse } from './sales-representatives-summary-response';

// @ts-ignore
import { SaveMessageFileAsPatientFileRequest } from './save-message-file-as-patient-file-request';

// @ts-ignore
import { ScheduleFilterRequest } from './schedule-filter-request';

// @ts-ignore
import { ScheduleFilterResponse } from './schedule-filter-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './schedule-item-calendar-response';

// @ts-ignore
import { ScheduleItemPrintResponse } from './schedule-item-print-response';

// @ts-ignore
import { ScheduleItemResponse } from './schedule-item-response';

// @ts-ignore
import { ScheduleItemSidebarResponse } from './schedule-item-sidebar-response';

// @ts-ignore
import { ScheduleItemsWithDeletedResponse } from './schedule-items-with-deleted-response';

// @ts-ignore
import { ScheduleViewPreferencesResponse } from './schedule-view-preferences-response';

// @ts-ignore
import { SchedulingPreferencesResponse } from './scheduling-preferences-response';

// @ts-ignore
import { SearchActivePracticeDiagnosesForAssessmentRequest } from './search-active-practice-diagnoses-for-assessment-request';

// @ts-ignore
import { SearchAppointmentSlotRequest } from './search-appointment-slot-request';

// @ts-ignore
import { SearchFeeScheduleItemsRequest } from './search-fee-schedule-items-request';

// @ts-ignore
import { SearchLoincCodesRequest } from './search-loinc-codes-request';

// @ts-ignore
import { SearchMessageParticipantPersonsRequest } from './search-message-participant-persons-request';

// @ts-ignore
import { SearchPatientFilesRequest } from './search-patient-files-request';

// @ts-ignore
import { SearchPracticeDiagnosesRequest } from './search-practice-diagnoses-request';

// @ts-ignore
import { SearchRefractionTestsRequest } from './search-refraction-tests-request';

// @ts-ignore
import { SearchSnomedCodesRequest } from './search-snomed-codes-request';

// @ts-ignore
import { SecureResourceCategoryResponse } from './secure-resource-category-response';

// @ts-ignore
import { SecureResourceResponse } from './secure-resource-response';

// @ts-ignore
import { SecurityRoleResponse } from './security-role-response';

// @ts-ignore
import { SendFeedbackRequest } from './send-feedback-request';

// @ts-ignore
import { SendOrderConnectMessageRequest } from './send-order-connect-message-request';

// @ts-ignore
import { SendStatementsRequest } from './send-statements-request';

// @ts-ignore
import { ServiceAdjustmentResponse } from './service-adjustment-response';

// @ts-ignore
import { ServiceLineResponse } from './service-line-response';

// @ts-ignore
import { ServiceSummaryResponse } from './service-summary-response';

// @ts-ignore
import { SignEncounterRequest } from './sign-encounter-request';

// @ts-ignore
import { SignEncounterSsoRequest } from './sign-encounter-sso-request';

// @ts-ignore
import { SmartflowMasterManufacturerResponse } from './smartflow-master-manufacturer-response';

// @ts-ignore
import { SmartflowPracticeSupplierResponse } from './smartflow-practice-supplier-response';

// @ts-ignore
import { SmartflowSupplierLocationResponse } from './smartflow-supplier-location-response';

// @ts-ignore
import { SmartflowSupplierResponse } from './smartflow-supplier-response';

// @ts-ignore
import { SnomedCodeResponse } from './snomed-code-response';

// @ts-ignore
import { SocialDiffResponse } from './social-diff-response';

// @ts-ignore
import { SocialHistoryRequest } from './social-history-request';

// @ts-ignore
import { SocialHistoryResponse } from './social-history-response';

// @ts-ignore
import { SortingRequest } from './sorting-request';

// @ts-ignore
import { SplitPaymentRequest } from './split-payment-request';

// @ts-ignore
import { StartAppointmentCalendarResponse } from './start-appointment-calendar-response';

// @ts-ignore
import { StartAppointmentRequest } from './start-appointment-request';

// @ts-ignore
import { StatementInvoiceResponse } from './statement-invoice-response';

// @ts-ignore
import { StatementPayerResponse } from './statement-payer-response';

// @ts-ignore
import { StopPrescriptionRequest } from './stop-prescription-request';

// @ts-ignore
import { StringAdditionalPreferenceRequest } from './string-additional-preference-request';

// @ts-ignore
import { StringAdditionalPreferenceResponse } from './string-additional-preference-response';

// @ts-ignore
import { StringValueDiffResponse } from './string-value-diff-response';

// @ts-ignore
import { SubmitClaimsRequest } from './submit-claims-request';

// @ts-ignore
import { SubscribeTwelve84SubscriptionRequest } from './subscribe-twelve84-subscription-request';

// @ts-ignore
import { SurgeryHistoryResponse } from './surgery-history-response';

// @ts-ignore
import { SystemPreferencesResponse } from './system-preferences-response';

// @ts-ignore
import { TargetIopResponse } from './target-iop-response';

// @ts-ignore
import { TaxResponse } from './tax-response';

// @ts-ignore
import { TemplateCategorySearchRequest } from './template-category-search-request';

// @ts-ignore
import { ThirdPartyAdministratorResponse } from './third-party-administrator-response';

// @ts-ignore
import { TodaysPatientsAppointmentResponse } from './todays-patients-appointment-response';

// @ts-ignore
import { TransactionResponse } from './transaction-response';

// @ts-ignore
import { TransferInvoiceBalanceToPatientRequest } from './transfer-invoice-balance-to-patient-request';

// @ts-ignore
import { TransferInvoiceItemsRequest } from './transfer-invoice-items-request';

// @ts-ignore
import { TransferItemRequest } from './transfer-item-request';

// @ts-ignore
import { TransferItemResponse } from './transfer-item-response';

// @ts-ignore
import { TransferPatientCreditRequest } from './transfer-patient-credit-request';

// @ts-ignore
import { TrizettoExternalCredentialResponse } from './trizetto-external-credential-response';

// @ts-ignore
import { Twelve84AgreementResponse } from './twelve84-agreement-response';

// @ts-ignore
import { Twelve84SubscriptionResponse } from './twelve84-subscription-response';

// @ts-ignore
import { Twelve84SubscriptionTableResponse } from './twelve84-subscription-table-response';

// @ts-ignore
import { TwoWayTextAutomatedMessageResponse } from './two-way-text-automated-message-response';

// @ts-ignore
import { TwoWayTextConversationListResponse } from './two-way-text-conversation-list-response';

// @ts-ignore
import { TwoWayTextConversationOrPatientMatchesResponse } from './two-way-text-conversation-or-patient-matches-response';

// @ts-ignore
import { TwoWayTextConversationResponse } from './two-way-text-conversation-response';

// @ts-ignore
import { TwoWayTextFindConversationsByClassificationRequest } from './two-way-text-find-conversations-by-classification-request';

// @ts-ignore
import { TwoWayTextFindNewConversationPatientsRequest } from './two-way-text-find-new-conversation-patients-request';

// @ts-ignore
import { TwoWayTextMessageResponse } from './two-way-text-message-response';

// @ts-ignore
import { TwoWayTextMessageSummaryResponse } from './two-way-text-message-summary-response';

// @ts-ignore
import { TwoWayTextNewConversationPatientResponse } from './two-way-text-new-conversation-patient-response';

// @ts-ignore
import { TwoWayTextPatientMatchResponse } from './two-way-text-patient-match-response';

// @ts-ignore
import { TwoWayTextPatientMatchesResponse } from './two-way-text-patient-matches-response';

// @ts-ignore
import { TwoWayTextSimpleSearchRequest } from './two-way-text-simple-search-request';

// @ts-ignore
import { UnassignedServiceResponse } from './unassigned-service-response';

// @ts-ignore
import { UnassignedServicesRequest } from './unassigned-services-request';

// @ts-ignore
import { UnlockSessionRequest } from './unlock-session-request';

// @ts-ignore
import { UnsignEncounterRequest } from './unsign-encounter-request';

// @ts-ignore
import { UnsignEncounterSsoRequest } from './unsign-encounter-sso-request';

// @ts-ignore
import { UpdateAdditionalClaimInformationRequest } from './update-additional-claim-information-request';

// @ts-ignore
import { UpdateAlertRequest } from './update-alert-request';

// @ts-ignore
import { UpdateAllergyReviewHistoryRequest } from './update-allergy-review-history-request';

// @ts-ignore
import { UpdateAmendmentRequest } from './update-amendment-request';

// @ts-ignore
import { UpdateAppointmentDescriptionRequest } from './update-appointment-description-request';

// @ts-ignore
import { UpdateAppointmentSlotRequest } from './update-appointment-slot-request';

// @ts-ignore
import { UpdateAssessmentPersonDiagnosisRequest } from './update-assessment-person-diagnosis-request';

// @ts-ignore
import { UpdateAssessmentPrimaryDiagnosisForEncounterRequest } from './update-assessment-primary-diagnosis-for-encounter-request';

// @ts-ignore
import { UpdateAssessmentRequest } from './update-assessment-request';

// @ts-ignore
import { UpdateBusyTimeRequest } from './update-busy-time-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateEducationRequest } from './update-care-plan-item-template-education-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateGeneralRequest } from './update-care-plan-item-template-general-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateGoalRequest } from './update-care-plan-item-template-goal-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateLabOrderRequest } from './update-care-plan-item-template-lab-order-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateMedicationPrescriptionRequest } from './update-care-plan-item-template-medication-prescription-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateRecallRequest } from './update-care-plan-item-template-recall-request';

// @ts-ignore
import { UpdateCarePlanTemplateRequest } from './update-care-plan-template-request';

// @ts-ignore
import { UpdateCategoryRequest } from './update-category-request';

// @ts-ignore
import { UpdateCategorySortOrderRequest } from './update-category-sort-order-request';

// @ts-ignore
import { UpdateCheckoutTaskRequest } from './update-checkout-task-request';

// @ts-ignore
import { UpdateClaimProcessingPreferencesRequest } from './update-claim-processing-preferences-request';

// @ts-ignore
import { UpdateClaimsProcessorAttributeRequest } from './update-claims-processor-attribute-request';

// @ts-ignore
import { UpdateCommunicationsPreferencesRequest } from './update-communications-preferences-request';

// @ts-ignore
import { UpdateConditionRelatedToRequest } from './update-condition-related-to-request';

// @ts-ignore
import { UpdateConnectCampaignDraftRequest } from './update-connect-campaign-draft-request';

// @ts-ignore
import { UpdateConnectCampaignScheduleRequest } from './update-connect-campaign-schedule-request';

// @ts-ignore
import { UpdateConnectPracticeRequest } from './update-connect-practice-request';

// @ts-ignore
import { UpdateContactLensHistoryRequest } from './update-contact-lens-history-request';

// @ts-ignore
import { UpdateContactLensOrderRequest } from './update-contact-lens-order-request';

// @ts-ignore
import { UpdateContactLensPrescriptionDeliveryMethodRequest } from './update-contact-lens-prescription-delivery-method-request';

// @ts-ignore
import { UpdateContactLensPrescriptionRequest } from './update-contact-lens-prescription-request';

// @ts-ignore
import { UpdateContactLensTrialOrderRequest } from './update-contact-lens-trial-order-request';

// @ts-ignore
import { UpdateContactLensTrialRequest } from './update-contact-lens-trial-request';

// @ts-ignore
import { UpdateContactRequest } from './update-contact-request';

// @ts-ignore
import { UpdateCustomFilterPreferencesRequest } from './update-custom-filter-preferences-request';

// @ts-ignore
import { UpdateCustomQueryRequest } from './update-custom-query-request';

// @ts-ignore
import { UpdateDescriptionAndCommentsCheckoutTaskRequest } from './update-description-and-comments-checkout-task-request';

// @ts-ignore
import { UpdateDiagnosesOnPerformedServiceRequest } from './update-diagnoses-on-performed-service-request';

// @ts-ignore
import { UpdateDrugAllergyRequest } from './update-drug-allergy-request';

// @ts-ignore
import { UpdateEmployeeOfficeHoursRequest } from './update-employee-office-hours-request';

// @ts-ignore
import { UpdateEncounterProviderRequest } from './update-encounter-provider-request';

// @ts-ignore
import { UpdateEncounterRoleRequest } from './update-encounter-role-request';

// @ts-ignore
import { UpdateEncounterTemplateRequest } from './update-encounter-template-request';

// @ts-ignore
import { UpdateExternalCredentialRequest } from './update-external-credential-request';

// @ts-ignore
import { UpdateExternalDataRequest } from './update-external-data-request';

// @ts-ignore
import { UpdateExternalProviderRequest } from './update-external-provider-request';

// @ts-ignore
import { UpdateEyefinityContactLensOrderRequest } from './update-eyefinity-contact-lens-order-request';

// @ts-ignore
import { UpdateEyefinityEyeglassOrderRequest } from './update-eyefinity-eyeglass-order-request';

// @ts-ignore
import { UpdateEyefinityFrameOrderRequest } from './update-eyefinity-frame-order-request';

// @ts-ignore
import { UpdateEyefinityItemsAsBillableRequest } from './update-eyefinity-items-as-billable-request';

// @ts-ignore
import { UpdateEyefinityItemsAsNonBillableRequest } from './update-eyefinity-items-as-non-billable-request';

// @ts-ignore
import { UpdateEyefinityOrderRequest } from './update-eyefinity-order-request';

// @ts-ignore
import { UpdateEyeglassLensMeasurementsRequest } from './update-eyeglass-lens-measurements-request';

// @ts-ignore
import { UpdateEyeglassLensOrderDetailRequest } from './update-eyeglass-lens-order-detail-request';

// @ts-ignore
import { UpdateEyeglassOrderRequest } from './update-eyeglass-order-request';

// @ts-ignore
import { UpdateEyeglassPrescriptionRequest } from './update-eyeglass-prescription-request';

// @ts-ignore
import { UpdateFamilyAccountMemberRequest } from './update-family-account-member-request';

// @ts-ignore
import { UpdateFamilyAccountMemberWithEmergencyContactRequest } from './update-family-account-member-with-emergency-contact-request';

// @ts-ignore
import { UpdateFeatureFlagEmployeeRequest } from './update-feature-flag-employee-request';

// @ts-ignore
import { UpdateFeeScheduleRangeRequest } from './update-fee-schedule-range-request';

// @ts-ignore
import { UpdateFeeScheduleRequest } from './update-fee-schedule-request';

// @ts-ignore
import { UpdateFolderRequest } from './update-folder-request';

// @ts-ignore
import { UpdateFrameOrderRequest } from './update-frame-order-request';

// @ts-ignore
import { UpdateFramesDataSubscriptionRequest } from './update-frames-data-subscription-request';

// @ts-ignore
import { UpdateGeneralMedicationPrescriptionRequest } from './update-general-medication-prescription-request';

// @ts-ignore
import { UpdateIntakeAllergiesRequest } from './update-intake-allergies-request';

// @ts-ignore
import { UpdateIntakeMedicationRequest } from './update-intake-medication-request';

// @ts-ignore
import { UpdateInterviewRequest } from './update-interview-request';

// @ts-ignore
import { UpdateInterviewTemplateRequest } from './update-interview-template-request';

// @ts-ignore
import { UpdateInvoiceAppointmentRequest } from './update-invoice-appointment-request';

// @ts-ignore
import { UpdateInvoiceDateRequest } from './update-invoice-date-request';

// @ts-ignore
import { UpdateInvoiceEncounterRequest } from './update-invoice-encounter-request';

// @ts-ignore
import { UpdateInvoiceFinanceChargePlanRequest } from './update-invoice-finance-charge-plan-request';

// @ts-ignore
import { UpdateInvoiceItemQuantityRequest } from './update-invoice-item-quantity-request';

// @ts-ignore
import { UpdateInvoiceItemRequest } from './update-invoice-item-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonListRequest } from './update-invoice-item-responsible-person-list-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonRequest } from './update-invoice-item-responsible-person-request';

// @ts-ignore
import { UpdateInvoiceLocationRequest } from './update-invoice-location-request';

// @ts-ignore
import { UpdateInvoiceProviderRequest } from './update-invoice-provider-request';

// @ts-ignore
import { UpdateLabOrderRequest } from './update-lab-order-request';

// @ts-ignore
import { UpdateManufacturerRequest } from './update-manufacturer-request';

// @ts-ignore
import { UpdateMedicalDecisionMakingRequest } from './update-medical-decision-making-request';

// @ts-ignore
import { UpdateMedicationPrescriptionRequest } from './update-medication-prescription-request';

// @ts-ignore
import { UpdateMedicationReviewHistoryRequest } from './update-medication-review-history-request';

// @ts-ignore
import { UpdateMentalStatusRequest } from './update-mental-status-request';

// @ts-ignore
import { UpdateMessageParticipantPersonFolderRequest } from './update-message-participant-person-folder-request';

// @ts-ignore
import { UpdateNonBillableRequest } from './update-non-billable-request';

// @ts-ignore
import { UpdateNoteRequest } from './update-note-request';

// @ts-ignore
import { UpdateNotificationPersonsRequest } from './update-notification-persons-request';

// @ts-ignore
import { UpdateOfficeClosedRequest } from './update-office-closed-request';

// @ts-ignore
import { UpdateOfficeHoursDailyRequest } from './update-office-hours-daily-request';

// @ts-ignore
import { UpdateOfficeHoursWeeklyRequest } from './update-office-hours-weekly-request';

// @ts-ignore
import { UpdateOpenEdgeCardReaderRequest } from './update-open-edge-card-reader-request';

// @ts-ignore
import { UpdateOpenEdgeSettingsRequest } from './update-open-edge-settings-request';

// @ts-ignore
import { UpdateOrderOnHoldRequest } from './update-order-on-hold-request';

// @ts-ignore
import { UpdateOrderRequest } from './update-order-request';

// @ts-ignore
import { UpdateOrderStatusRequest } from './update-order-status-request';

// @ts-ignore
import { UpdateOtherAllergyRequest } from './update-other-allergy-request';

// @ts-ignore
import { UpdatePasswordPolicyRequest } from './update-password-policy-request';

// @ts-ignore
import { UpdatePatientCommentRequest } from './update-patient-comment-request';

// @ts-ignore
import { UpdatePatientConsentRequest } from './update-patient-consent-request';

// @ts-ignore
import { UpdatePatientFileRequest } from './update-patient-file-request';

// @ts-ignore
import { UpdatePatientGeneralBenefitsRequest } from './update-patient-general-benefits-request';

// @ts-ignore
import { UpdatePatientGeneralBenefitsSelfPolicyRequest } from './update-patient-general-benefits-self-policy-request';

// @ts-ignore
import { UpdatePatientHealthConcernRequest } from './update-patient-health-concern-request';

// @ts-ignore
import { UpdatePatientHealthGoalRequest } from './update-patient-health-goal-request';

// @ts-ignore
import { UpdatePatientImplantableDeviceRequest } from './update-patient-implantable-device-request';

// @ts-ignore
import { UpdatePatientInsuranceBasicInformationRequest } from './update-patient-insurance-basic-information-request';

// @ts-ignore
import { UpdatePatientInsuranceBasicInformationSelfPolicyRequest } from './update-patient-insurance-basic-information-self-policy-request';

// @ts-ignore
import { UpdatePatientMaterialBenefitsRequest } from './update-patient-material-benefits-request';

// @ts-ignore
import { UpdatePatientMaterialBenefitsSelfPolicyRequest } from './update-patient-material-benefits-self-policy-request';

// @ts-ignore
import { UpdatePatientOngoingCareRequest } from './update-patient-ongoing-care-request';

// @ts-ignore
import { UpdatePatientProviderRequest } from './update-patient-provider-request';

// @ts-ignore
import { UpdatePatientQuickViewRequest } from './update-patient-quick-view-request';

// @ts-ignore
import { UpdatePatientRecallRequest } from './update-patient-recall-request';

// @ts-ignore
import { UpdatePatientRequest } from './update-patient-request';

// @ts-ignore
import { UpdatePatientTriggerRequest } from './update-patient-trigger-request';

// @ts-ignore
import { UpdatePatientVaccinationRequest } from './update-patient-vaccination-request';

// @ts-ignore
import { UpdatePaymentGroupRequest } from './update-payment-group-request';

// @ts-ignore
import { UpdatePaymentPreferencesRequest } from './update-payment-preferences-request';

// @ts-ignore
import { UpdatePersonDiagnosisLocationAndQualifierRequest } from './update-person-diagnosis-location-and-qualifier-request';

// @ts-ignore
import { UpdatePersonDiagnosisRequest } from './update-person-diagnosis-request';

// @ts-ignore
import { UpdatePersonGeneralHistoryRequest } from './update-person-general-history-request';

// @ts-ignore
import { UpdatePersonInsuranceAndPolicyRequest } from './update-person-insurance-and-policy-request';

// @ts-ignore
import { UpdatePersonInsuranceRequest } from './update-person-insurance-request';

// @ts-ignore
import { UpdatePharmacyRequest } from './update-pharmacy-request';

// @ts-ignore
import { UpdatePracticeConsentFormRequest } from './update-practice-consent-form-request';

// @ts-ignore
import { UpdatePracticeDiagnosisRequest } from './update-practice-diagnosis-request';

// @ts-ignore
import { UpdatePracticeDrugRequest } from './update-practice-drug-request';

// @ts-ignore
import { UpdatePracticeFamilyHealthFiltersRequest } from './update-practice-family-health-filters-request';

// @ts-ignore
import { UpdatePracticeFileRequest } from './update-practice-file-request';

// @ts-ignore
import { UpdatePracticeGeneralInfoRequest } from './update-practice-general-info-request';

// @ts-ignore
import { UpdatePracticeInsuranceCompanyRequest } from './update-practice-insurance-company-request';

// @ts-ignore
import { UpdatePracticeInsuranceRemittanceCodeAdjustmentRequest } from './update-practice-insurance-remittance-code-adjustment-request';

// @ts-ignore
import { UpdatePracticeLocationTaxesRequest } from './update-practice-location-taxes-request';

// @ts-ignore
import { UpdatePracticeLocationTransferTaxRequest } from './update-practice-location-transfer-tax-request';

// @ts-ignore
import { UpdatePracticeLocationTransferTaxesRequest } from './update-practice-location-transfer-taxes-request';

// @ts-ignore
import { UpdatePracticeModifiersRequest } from './update-practice-modifiers-request';

// @ts-ignore
import { UpdatePracticeRemittanceCodeAdjustmentRequest } from './update-practice-remittance-code-adjustment-request';

// @ts-ignore
import { UpdatePresentIllnessRequest } from './update-present-illness-request';

// @ts-ignore
import { UpdateProviderInsuranceIdentifierRequest } from './update-provider-insurance-identifier-request';

// @ts-ignore
import { UpdateQueryRequest } from './update-query-request';

// @ts-ignore
import { UpdateQueryTriggerRequest } from './update-query-trigger-request';

// @ts-ignore
import { UpdateReasonForVisitForSignedEncounterRequest } from './update-reason-for-visit-for-signed-encounter-request';

// @ts-ignore
import { UpdateReasonForVisitRequest } from './update-reason-for-visit-request';

// @ts-ignore
import { UpdateRecurringAppointmentSlotRequest } from './update-recurring-appointment-slot-request';

// @ts-ignore
import { UpdateRecurringBusyTimeRequest } from './update-recurring-busy-time-request';

// @ts-ignore
import { UpdateRecurringDefinitionRequest } from './update-recurring-definition-request';

// @ts-ignore
import { UpdateRecurringEmployeeOfficeHoursRequest } from './update-recurring-employee-office-hours-request';

// @ts-ignore
import { UpdateRecurringOfficeClosedRequest } from './update-recurring-office-closed-request';

// @ts-ignore
import { UpdateReferenceDataRequest } from './update-reference-data-request';

// @ts-ignore
import { UpdateReferenceDataSortOrderRequest } from './update-reference-data-sort-order-request';

// @ts-ignore
import { UpdateRelatedCategoryRequest } from './update-related-category-request';

// @ts-ignore
import { UpdateRevClearConfigurationRequest } from './update-rev-clear-configuration-request';

// @ts-ignore
import { UpdateRevClearExternalCredentialRequest } from './update-rev-clear-external-credential-request';

// @ts-ignore
import { UpdateSalesRepresentativeRequest } from './update-sales-representative-request';

// @ts-ignore
import { UpdateScheduleViewPreferencesRequest } from './update-schedule-view-preferences-request';

// @ts-ignore
import { UpdateSchedulingPreferenceRequest } from './update-scheduling-preference-request';

// @ts-ignore
import { UpdateSurgeryHistoryRequest } from './update-surgery-history-request';

// @ts-ignore
import { UpdateThirdPartyAdministratorRequest } from './update-third-party-administrator-request';

// @ts-ignore
import { UpdateTrizettoExternalCredentialRequest } from './update-trizetto-external-credential-request';

// @ts-ignore
import { UpdateTwelve84SubscriptionRequest } from './update-twelve84-subscription-request';

// @ts-ignore
import { UpdateTwoWayTextConversationClassificationRequest } from './update-two-way-text-conversation-classification-request';

// @ts-ignore
import { UpdateTwoWayTextConversationPatientRequest } from './update-two-way-text-conversation-patient-request';

// @ts-ignore
import { UpdateUserRoleRequest } from './update-user-role-request';

// @ts-ignore
import { UpdateVendorLocationConfigRequest } from './update-vendor-location-config-request';

// @ts-ignore
import { UpdateVendorRequest } from './update-vendor-request';

// @ts-ignore
import { UpdateWaitingListRequest } from './update-waiting-list-request';

// @ts-ignore
import { UpdateWorkflowStepTemplateRequest } from './update-workflow-step-template-request';

// @ts-ignore
import { UpdateWorkflowTestsRequest } from './update-workflow-tests-request';

// @ts-ignore
import { UpdatedAssessmentPersonDiagnosisResponse } from './updated-assessment-person-diagnosis-response';

// @ts-ignore
import { UserSessionLogRequest } from './user-session-log-request';

// @ts-ignore
import { UserSessionLogResponse } from './user-session-log-response';

// @ts-ignore
import { UserSessionLogSearchRequest } from './user-session-log-search-request';

// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

// @ts-ignore
import { ValidatePracticeLocationAddressResponse } from './validate-practice-location-address-response';

// @ts-ignore
import { ValidatePracticeRemittanceX12CodeRequest } from './validate-practice-remittance-x12-code-request';

// @ts-ignore
import { ValidateUsernameRequest } from './validate-username-request';

// @ts-ignore
import { ValidatedCreateAppointmentResponse } from './validated-create-appointment-response';

// @ts-ignore
import { VendorLocationConfigResponse } from './vendor-location-config-response';

// @ts-ignore
import { VendorResponse } from './vendor-response';

// @ts-ignore
import { VendorSearchRequest } from './vendor-search-request';

// @ts-ignore
import { VendorSummaryResponse } from './vendor-summary-response';

// @ts-ignore
import { VerifyInsuranceRequest } from './verify-insurance-request';

// @ts-ignore
import { VisionWebSupplierResponse } from './vision-web-supplier-response';

// @ts-ignore
import { VoidInsuranceCreditRequest } from './void-insurance-credit-request';

// @ts-ignore
import { VoidInsuranceRefundRequest } from './void-insurance-refund-request';

// @ts-ignore
import { VoidInvoiceRequest } from './void-invoice-request';

// @ts-ignore
import { VoidPatientCreditRequest } from './void-patient-credit-request';

// @ts-ignore
import { VoidPatientRefundRequest } from './void-patient-refund-request';

// @ts-ignore
import { VoidPaymentRequest } from './void-payment-request';

// @ts-ignore
import { WaitingListResponse } from './waiting-list-response';

// @ts-ignore
import { WebServiceUserResponse } from './web-service-user-response';

// @ts-ignore
import { WorkflowScreenTemplatePresentIllnessResponse } from './workflow-screen-template-present-illness-response';

// @ts-ignore
import { WorkflowScreenTestTemplateLayoutRequest } from './workflow-screen-test-template-layout-request';

// @ts-ignore
import { WorkflowStepTemplateResponse } from './workflow-step-template-response';

// @ts-ignore
import { WorkflowStepTemplateScreenResponse } from './workflow-step-template-screen-response';

// @ts-ignore
import { WorkflowTemplateStepRequest } from './workflow-template-step-request';

// @ts-ignore
import { WorkflowTestRequest } from './workflow-test-request';

// @ts-ignore
import { WorkflowTestTriggersResultResponse } from './workflow-test-triggers-result-response';

// @ts-ignore
import { WriteoffInvoiceTransferSplitRequest } from './writeoff-invoice-transfer-split-request';

// @ts-ignore
import { X12CodeExistsRequest } from './x12-code-exists-request';


/* istanbul ignore next */
export const MODEL_STORE: any = {

	AccessLogSearchRequest,

	AccessLogSearchResponse,

	AccountingCheckIfInvoiceHasDirectPaymentRequest,

	AccountingInvoicePaymentResponse,

	AccountingPaymentPreferencesResponse,

	AccountingUpdatePaymentGroupRequest,

	AddAdhocInvoiceItemRequest,

	AddAutoDiagnosisToEncounterRequest,

	AddBillableItemsBillingRequest,

	AddDiagnosesToPerformedServicesRequest,

	AddDiscountItemRequest,

	AddDiscountItemsRequest,

	AddDiscountRequest,

	AddDynamicTestToDynamicScreenRequest,

	AddFamilyMemberRequest,

	AddFamilyMemberWithEmergencyContactRequest,

	AddInvoiceItemRequest,

	AddMasterDiagnosisToEncounterRequest,

	AddPatientOwnedFrameRequest,

	AddPatientOwnedFrameResponse,

	AddPerformedServicesRequest,

	AddPqriAlertsToEncounterRequest,

	AdditionalClaimInformationCanadaRequest,

	AdditionalClaimInformationCanadaResponse,

	AdditionalClaimInformationResponse,

	AdditionalPreferencesRequest,

	AdditionalPreferencesResponse,

	AddressOptionalRequest,

	AddressRequiredRequest,

	AddressResponse,

	AdminAccessCodeRequest,

	AdminAccessTimeRequest,

	AdminAccessTimeResponse,

	AdminAddWorkflowTestTemplateFieldOptionRequest,

	AdminCopyEyefinityFeeScheduleToLocationRequest,

	AdminCreateEmployeeExternalCredentialRequest,

	AdminCreateEmployeeLoginDetailsRequest,

	AdminCreateEmployeeRequest,

	AdminCreateEyefinityFeeScheduleRequest,

	AdminCreatePracticeLocationDetailsRequest,

	AdminCreatePracticeLocationFileDeviceRequest,

	AdminCreatePracticeLocationRequest,

	AdminCreatePracticeLocationSerialDeviceRequest,

	AdminCreateProviderInsuranceIdentifierRequest,

	AdminCreateWorkflowScreenTemplateRequest,

	AdminCreateWorkflowTestTemplateSvcTriggerRequest,

	AdminEditWorkflowTestTemplateFieldOptionRequest,

	AdminEmployeeDetailedResponse,

	AdminEmployeeExternalCredentialResponse,

	AdminEmployeeExternalSystemsRequest,

	AdminEmployeeExternalSystemsResponse,

	AdminEmployeeForEmployeeListResponse,

	AdminEmployeeLimitedResponse,

	AdminEmployeeLocationAccessRequest,

	AdminEmployeeLocationAccessResponse,

	AdminEmployeeLoginDetailsResponse,

	AdminEmployeeLoginRestrictionRequest,

	AdminEmployeeLoginRestrictionResponse,

	AdminEmployeeProviderDetailsRequest,

	AdminEmployeeProviderDetailsResponse,

	AdminEmployeeRolesRequest,

	AdminEmployeeRolesResponse,

	AdminEncounterTemplateResponse,

	AdminEyefinityFeeLensRequest,

	AdminEyefinityFeeLensResponse,

	AdminEyefinityFeeOptionRequest,

	AdminEyefinityFeeOptionResponse,

	AdminEyefinityFeeScheduleListResponse,

	AdminEyefinityFeeScheduleRequest,

	AdminEyefinityFeeScheduleResponse,

	AdminEyefinityFeeScheduleTemplateResponse,

	AdminEyefinityFeeServiceRequest,

	AdminEyefinityFeeServiceResponse,

	AdminFindWorkflowTestTemplateSvcTriggerRequest,

	AdminInsuranceCreditResponse,

	AdminInsuranceRefundResponse,

	AdminLocationOrderProcessingResponse,

	AdminLocationOrderProcessingVisionwebRequest,

	AdminLocationOrderProcessingVisionwebResponse,

	AdminLocationPhoneRequest,

	AdminLocationTaxResponse,

	AdminOpenEdgeCardReaderResponse,

	AdminPracticeInsuranceCompanySummaryResponse,

	AdminPracticeLocationAddressResponse,

	AdminPracticeLocationDetailsRequest,

	AdminPracticeLocationDetailsResponse,

	AdminPracticeLocationDistributionCenterRequest,

	AdminPracticeLocationDistributionCenterResponse,

	AdminPracticeLocationExternalSystemsResponse,

	AdminPracticeLocationOfficeHoursResponse,

	AdminPracticeLocationOrderProcessingRequest,

	AdminPracticeLocationPhrRequest,

	AdminPracticeLocationPhrResponse,

	AdminPracticeLocationResponse,

	AdminSmartApplicationResponse,

	AdminUpdateEmployeeExternalCredentialRequest,

	AdminUpdateEmployeeLoginDetailsRequest,

	AdminUpdateEmployeeRequest,

	AdminUpdateEyefinityFeeScheduleRequest,

	AdminUpdatePracticeLocationFileDeviceRequest,

	AdminUpdatePracticeLocationRequest,

	AdminUpdatePracticeLocationSerialDeviceRequest,

	AdminUpdateProviderInsuranceIdentifierRequest,

	AdminUpdateWorkflowScreenTemplateRequest,

	AdminUpdateWorkflowTestTemplateRequest,

	AdminUpdateWorkflowTestTemplateSvcTriggerRequest,

	AdminWorkflowScreenTemplateListResponse,

	AdminWorkflowScreenTemplateResponse,

	AdminWorkflowScreenTemplateTestResponse,

	AdminWorkflowTestTemplateFormFieldRequest,

	AdminWorkflowTestTemplateListResponse,

	AdminWorkflowTestTemplateSvcTriggerDetailResponse,

	AdminWorkflowTestTemplateSvcTriggerResponse,

	AdvancedAgeReportRequest,

	AdvancedCostReportRequest,

	AdvancedInventoryMinMaxReportRequest,

	AdvancedInventoryReportRequest,

	AdvancedSalesReportRequest,

	AgingReportRequest,

	AgingReportResponse,

	AgreementDetailsResponse,

	AlertResponse,

	AllergyListResponse,

	AllergyRequest,

	AllergyResponse,

	AllergyReviewHistoryRequest,

	AllergyReviewHistoryResponse,

	AmendmentListResponse,

	AmendmentResponse,

	AncillaryParticipantResponse,

	AppointmentCalendarResponse,

	AppointmentExamHistoryListResponse,

	AppointmentHistoryResponse,

	AppointmentInsuranceVerificationResponse,

	AppointmentReportRequest,

	AppointmentReportResponse,

	AppointmentResponse,

	AppointmentSidebarResponse,

	AppointmentSlotCalendarResponse,

	AppointmentSlotResponse,

	AppointmentSlotSidebarResponse,

	ApproveInvoiceRequest,

	AskQuestionRequest,

	AssessmentAutoDiagnosisResponse,

	AssessmentDiagnosisReviewRequest,

	AssessmentDiagnosisReviewResponse,

	AssessmentPersonDiagnosisResponse,

	AssessmentPracticeDiagnosisResponse,

	AssessmentResponse,

	AssignFeeScheduleItemListRequest,

	AssignFeeScheduleItemRequest,

	AssignRelatedCategoriesRequest,

	AssociateMeasurementWithOrderRequest,

	AttributeDropdownResponse,

	AuthorizeContactLensPrescriptionRequest,

	AuthorizeEyeglassPrescriptionsRequest,

	AuthorizeMedicationPrescriptionRequest,

	BarcodeSearchRequest,

	BarcodeSearchResponse,

	BaseAgeReportResponse,

	BaseCostReportResponse,

	BaseInventoryReportResponse,

	BaseSalesReportResponse,

	BasicAgeReportRequest,

	BasicCostReportRequest,

	BasicInventoryMinMaxReportRequest,

	BasicInventoryReportRequest,

	BasicSalesReportRequest,

	BillableItemProductResponse,

	BillingInvoiceResponse,

	BillingPayerResponse,

	BillingServiceResponse,

	BridgeExportResponse,

	BridgeExportTokenRequest,

	BulkCancelOrdersRequest,

	BulkNotifyPatientsRequest,

	BulkUpdateOrderRequest,

	BusinessPhonesResponse,

	BusyTimeCalendarResponse,

	BusyTimeResponse,

	BusyTimeSidebarResponse,

	CanSendPatientMessageRequest,

	CancelAppointmentRequest,

	CancelEncounterRequest,

	CancelOrderRequest,

	CancelPrescriptionRequest,

	CarePlanItemHistoryResponse,

	CarePlanItemResponse,

	CarePlanItemTemplateEducationResponse,

	CarePlanItemTemplateGeneralResponse,

	CarePlanItemTemplateGoalResponse,

	CarePlanItemTemplateLabOrderResponse,

	CarePlanItemTemplateListResponse,

	CarePlanItemTemplateMedicationPrescriptionResponse,

	CarePlanItemTemplateRecallResponse,

	CarePlanItemTemplateResponse,

	CarePlanTemplateListResponse,

	CarePlanTemplateResponse,

	CategoryResponse,

	CcdaSectionsPreferenceRequest,

	CcdaSectionsPreferenceResponse,

	ChangeEyefinityOrderRequest,

	ChangeOrderRequest,

	CheckOdOsLocationProductIdsActiveRequest,

	CheckoutTaskResponse,

	CityStateProvinceResponse,

	ClaimProcessingPreferencesResponse,

	ClaimSearchRequest,

	ClaimSubmitErrorResponse,

	ClaimSubmitResponse,

	ClaimsInsuranceClaimResponse,

	ClaimsProcessorAttributeResponse,

	CodeEncounterRequest,

	CollectionsInvoiceTransferSplitRequest,

	CommunicationsPreferencesResponse,

	ConditionRelatedToResponse,

	ConfirmAppointmentRequest,

	ConnectCampaignListResponse,

	ConnectCampaignMessageListResponse,

	ConnectCampaignMessagePagedResponse,

	ConnectCampaignMessagePreviewRequest,

	ConnectCampaignResponse,

	ConnectCampaignSendTestEmailRequest,

	ConnectCampaignTemplateResponse,

	ConnectMessageForPatientAppointmentRequest,

	ConnectMessageForPatientRecallRequest,

	ConnectMessageForWaitingListRequest,

	ConnectPracticeResponse,

	ConnectSummaryConnectMessageListResponse,

	ConsentFormListResponse,

	ConsentFormResponse,

	ContactDemographicsRequest,

	ContactInformationRequest,

	ContactLensAgeReportResponse,

	ContactLensCostReportResponse,

	ContactLensEyeHybridRequest,

	ContactLensEyeHybridResponse,

	ContactLensEyeOptionsResponse,

	ContactLensEyeRequest,

	ContactLensEyeResponse,

	ContactLensEyeRgpRequest,

	ContactLensEyeRgpResponse,

	ContactLensEyeSoftRequest,

	ContactLensEyeSoftResponse,

	ContactLensHistoryResponse,

	ContactLensInventoryReportResponse,

	ContactLensOrderResponse,

	ContactLensOrderSearchResponse,

	ContactLensPrescriptionDetailsResponse,

	ContactLensPrescriptionLookupResponse,

	ContactLensPrescriptionResponse,

	ContactLensPropertyValuesResponse,

	ContactLensSalesReportResponse,

	ContactLensTrialDetailResponse,

	ContactLensTrialListResponse,

	ContactLensTrialOrderResponse,

	ContactLensTrialResponse,

	ContactListResponse,

	ContactPersonNameResponse,

	ContactResponse,

	ContactWithEmergencyContactResponse,

	ContinuityOfCareImportResultResponse,

	CopyFeeScheduleRangeRequest,

	CqmReportRequest,

	CqmReportResponse,

	CreateAlertRequest,

	CreateAllergyReviewHistoryRequest,

	CreateAmendmentRequest,

	CreateAncillaryParticipantRequest,

	CreateAppointmentRequest,

	CreateAppointmentSlotRequest,

	CreateAssessmentDiagnosisReviewRequest,

	CreateAssessmentPersonDiagnosisRequest,

	CreateAttributeRequest,

	CreateBillingInvoiceRequest,

	CreateBusyTimeRequest,

	CreateCarePlanItemTemplateEducationRequest,

	CreateCarePlanItemTemplateGeneralRequest,

	CreateCarePlanItemTemplateGoalRequest,

	CreateCarePlanItemTemplateLabOrderRequest,

	CreateCarePlanItemTemplateMedicationPrescriptionRequest,

	CreateCarePlanItemTemplateRecallRequest,

	CreateCarePlanItemTemplateRequest,

	CreateCarePlanTemplateRequest,

	CreateCategoryRequest,

	CreateCheckoutTaskRequest,

	CreateConnectCampaignDraftRequest,

	CreateConnectCampaignScheduleRequest,

	CreateConnectPracticeRequest,

	CreateContactLensOrderRequest,

	CreateContactLensPrescriptionRequest,

	CreateContactLensTrialOrderBasicRequest,

	CreateContactLensTrialOrderRequest,

	CreateContactLensTrialRequest,

	CreateContactRequest,

	CreateDiagnosisReviewHistoryRequest,

	CreateDrugAllergyRequest,

	CreateEmployeeOfficeHoursRequest,

	CreateEncounterRequest,

	CreateEncounterRoleRequest,

	CreateEncounterTemplateRequest,

	CreateExternalCredentialForPracticeLocationRequest,

	CreateExternalDataRequest,

	CreateExternalProviderRequest,

	CreateEyeglassOrderRequest,

	CreateEyeglassPrescriptionBaseRequest,

	CreateEyeglassPrescriptionFromRefractionRequest,

	CreateEyeglassPrescriptionRequest,

	CreateEyeglassPrescriptionsRequest,

	CreateFamilyHealthHistoryRequest,

	CreateFamilyHealthHistoryReviewSetRequest,

	CreateFeatureFlagEmployeeRequest,

	CreateFeeScheduleRangeRequest,

	CreateFeeScheduleRequest,

	CreateFinanceChargePlanPeriodRequest,

	CreateFinanceChargePlanRequest,

	CreateFolderRequest,

	CreateFrameOrderRequest,

	CreateFramesDataSubscriptionRequest,

	CreateGeneralMedicationPrescriptionRequest,

	CreateGuestInvoiceRequest,

	CreateInterviewTemplateRequest,

	CreateInvoiceRequest,

	CreateItemQuantityChangePurchaseOrderRequest,

	CreateItemQuantityChangeRequest,

	CreateLabOrderRequest,

	CreateManufacturerRequest,

	CreateMedicationPrescriptionRequest,

	CreateMedicationReviewHistoryRequest,

	CreateMessageFolderRequest,

	CreateMessageParticipantRequest,

	CreateMessageRequest,

	CreateNoteRequest,

	CreateOfficeClosedRequest,

	CreateOpenEdgeCardReaderRequest,

	CreateOpenEdgeSettingsRequest,

	CreateOrUpdateHistoryDiagnosisCarePlanItemRequest,

	CreateOrderFromEyeglassPrescriptionRequest,

	CreateOrderRequest,

	CreateOtherAllergyRequest,

	CreatePatientBasicInformationRequest,

	CreatePatientCommunicationPreferencesRequest,

	CreatePatientConsentRequest,

	CreatePatientCreditRequest,

	CreatePatientDocumentRequest,

	CreatePatientFamilyDemographicsRequest,

	CreatePatientFamilyInformationRequest,

	CreatePatientFamilyInsuranceRequest,

	CreatePatientFileRequest,

	CreatePatientGeneralBenefitsRequest,

	CreatePatientHealthConcernRequest,

	CreatePatientHealthGoalRequest,

	CreatePatientImplantableDeviceRequest,

	CreatePatientInsuranceBasicInformationRequest,

	CreatePatientMaterialBenefitsRequest,

	CreatePatientOngoingCareRequest,

	CreatePatientOtherInformationRequest,

	CreatePatientPharmacyRequest,

	CreatePatientPhoneInformationRequest,

	CreatePatientProviderRequest,

	CreatePatientRecallRequest,

	CreatePatientRequest,

	CreatePatientTargetIopRequest,

	CreatePatientVaccinationRequest,

	CreatePaymentGroupRequest,

	CreatePaymentRequest,

	CreatePersonDiagnosisRequest,

	CreatePersonInsuranceRequest,

	CreatePharmacyRequest,

	CreatePracticeConsentFormRequest,

	CreatePracticeDiagnosisRequest,

	CreatePracticeDrugRequest,

	CreatePracticeInsuranceCompanyModalRequest,

	CreatePracticeInsuranceCompanyRequest,

	CreatePracticeInsuranceRemittanceCodeAdjustmentRequest,

	CreatePracticeRemittanceCodeAdjustmentRequest,

	CreatePrescriptionOrderRequest,

	CreatePresentIllnessRequest,

	CreatePresentIllnessesFromDiagnosesRequest,

	CreateProductBrandCollectionRequest,

	CreateProductBrandRequest,

	CreateProfessionalTimeAdjustmentRequest,

	CreateProviderInsuranceIdentifierRequest,

	CreateQueryRequest,

	CreateQueryTriggerRequest,

	CreateRecurPatternDailyRequest,

	CreateRecurPatternMonthlyRequest,

	CreateRecurPatternWeeklyRequest,

	CreateRecurPatternYearlyRequest,

	CreateRecurringDefinitionRequest,

	CreateReferenceDataRequest,

	CreateRefundPatientRequest,

	CreateRelatedCategoryRequest,

	CreateRescheduledAppointmentRequest,

	CreateRevClearExternalCredentialRequest,

	CreateSalesRepresentativeRequest,

	CreateSurgeryHistoryRequest,

	CreateThirdPartyAdministratorRequest,

	CreateTrizettoExternalCredentialRequest,

	CreateTwoWayTextConversationRequest,

	CreateTwoWayTextMessageRequest,

	CreateUserRoleRequest,

	CreateVendorLocationConfigRequest,

	CreateVendorRequest,

	CreateWaitingListRequest,

	CreateWebServiceUserRequest,

	CreateWorkflowStepTemplateRequest,

	CreditInsuranceResponse,

	CreditPatientResponse,

	CustomFilterPreferencesResponse,

	CustomerAgreementsResponse,

	CustomerContactsResponse,

	CustomerContactsUpdateRequest,

	CustomerSmartflowAgreementsResponse,

	DailyOfficeHoursAdminResponse,

	DailyOfficeHoursResponse,

	DataLogDiffResponse,

	DeceasePatientRequest,

	DeleteClaimsRequest,

	DeleteContactRequest,

	DeleteFolderRequest,

	DeleteMessageParticipantPersonsRequest,

	DeleteProviderInsuranceIdentifierRequest,

	DeleteScheduleItemRequest,

	DepositSlipSearchRequest,

	DepositSlipSearchResponse,

	DepositsReportRequest,

	DepositsReportResponse,

	DiagnosisReviewHistoryResponse,

	DiagnosisSearchRequest,

	DiagnosisSearchResponse,

	DispenseOrderRequest,

	DrugAllergyImportResponse,

	DrugAllergyResponse,

	DrugClassResponse,

	DrugSearchRequest,

	DrugSearchResponse,

	DurationResponse,

	DynamicScreenResponse,

	DynamicTestHistoryResponse,

	DynamicTestResponse,

	EClaimsInsuranceClaimResponse,

	EClaimsSearchRequest,

	EmergencyContactStatusResponse,

	EmployeeEditProfileRequest,

	EmployeeEditProfileResponse,

	EmployeeExternalCredentialsRequest,

	EmployeeNameResponse,

	EmployeeOfficeHoursCalendarResponse,

	EmployeeOfficeHoursResponse,

	EmployeeOfficeHoursSidebarResponse,

	EmployeePreferencesRequest,

	EmployeePreferencesResponse,

	EmployeeResponse,

	EmployeeRxntCredentialResponse,

	EmployeeWithSecurityRolesResponse,

	EncounterAddCarePlanItemsToPersonDiagnosisRequest,

	EncounterAddCarePlanTemplateToPersonDiagnosesRequest,

	EncounterAutoCodePreviewResponse,

	EncounterCarePlanItemEducationResponse,

	EncounterCarePlanItemGeneralResponse,

	EncounterContactLensTrialRequest,

	EncounterCreateCarePlanItemForPersonDiagnosisRequest,

	EncounterCreateCarePlanItemsForPersonDiagnosesRequest,

	EncounterCreateContactLensTrialRequest,

	EncounterMedicalDecisionMakingCodingResponse,

	EncounterProfessionalTimeCodingResponse,

	EncounterPullForwardTableResponse,

	EncounterReportRequest,

	EncounterReportResponse,

	EncounterResponse,

	EncounterReviewDetailResponse,

	EncounterReviewGeneralInformationResponse,

	EncounterReviewGroupResponse,

	EncounterRoleResponse,

	EncounterStatusesResponse,

	EncounterStopCarePlanItemForPersonDiagnosisRequest,

	EncounterTableResponse,

	EncounterTemplateListResponse,

	EncounterTemplateResponse,

	EncounterUpdateCarePlanItemEducationRequest,

	EncounterUpdateCarePlanItemGeneralRequest,

	EncounterUpdateCarePlanItemRecallRequest,

	EncounterUpdateContactLensTrialRequest,

	ExternalCredentialLocationRequest,

	ExternalCredentialLocationResponse,

	ExternalDataListResponse,

	ExternalDataResponse,

	ExternalMedicationPrescriptionResponse,

	ExternalProviderDropdownResponse,

	ExternalProviderListResponse,

	ExternalProviderResponse,

	EyefinityContactLensOrderResponse,

	EyefinityEyeglassOrderResponse,

	EyefinityFrameOrderResponse,

	EyefinityListItemRequest,

	EyefinityListItemResponse,

	EyefinityOrderItemRequest,

	EyefinityOrderItemResponse,

	EyefinityOrderResponse,

	EyeglassFrameAgeReportResponse,

	EyeglassFrameCostReportResponse,

	EyeglassFrameInventoryReportResponse,

	EyeglassFrameSalesReportResponse,

	EyeglassLensMeasurementResponse,

	EyeglassLensOrderDetailResponse,

	EyeglassOrderResponse,

	EyeglassOrderSearchResponse,

	EyeglassPrescriptionEyeRequest,

	EyeglassPrescriptionEyeResponse,

	EyeglassPrescriptionListResponse,

	EyeglassPrescriptionResponse,

	FamilyAccountMemberDetailResponse,

	FamilyAccountMembersDetailResponse,

	FamilyCreditRequest,

	FamilyDiffResponse,

	FamilyHealthHistoryResponse,

	FamilyHealthHistoryReviewSetResponse,

	FamilyHistoryRequest,

	FamilyHistoryResponse,

	FamilyMemberContactInformationRequest,

	FamilyRefundRequest,

	FeatureFlagDetailResponse,

	FeatureFlagEmployeeSummaryResponse,

	FeatureFlagSummaryResponse,

	FeeScheduleDropdownResponse,

	FeeScheduleItemProductResponse,

	FeeScheduleItemServiceCptResponse,

	FeeScheduleItemServiceOtherResponse,

	FeeScheduleListResponse,

	FeeScheduleRangeResponse,

	FeeScheduleResponse,

	FieldDefinitionFamilyHistoryResponse,

	FieldDefinitionHistoryOfPresentIllnessResponse,

	FieldDefinitionResponse,

	FieldDefinitionReviewOfSystemsResponse,

	FieldOptionResponse,

	FieldValueBooleanRequest,

	FieldValueBooleanResponse,

	FieldValueDateRequest,

	FieldValueDateResponse,

	FieldValueDecimalRequest,

	FieldValueDecimalResponse,

	FieldValueLargeStringRequest,

	FieldValueLargeStringResponse,

	FieldValueMultiSelectionDiffResponse,

	FieldValueMultiSelectionRequest,

	FieldValueMultiSelectionResponse,

	FieldValueNumberRequest,

	FieldValueNumberResponse,

	FieldValueRequest,

	FieldValueResponse,

	FieldValueSelectionRequest,

	FieldValueSelectionResponse,

	FieldValueSmallStringRequest,

	FieldValueSmallStringResponse,

	FileFolderResponse,

	FileInfoResponse,

	FinanceChargePlanNameRequest,

	FinanceChargePlanNameResponse,

	FinanceChargePlanPeriodResponse,

	FinanceChargePlanResponse,

	FindActiveLocationProductsRequest,

	FindActivePracticeDiagnosisForAssessmentRequest,

	FindAnonymousPaymentInvoicesRequest,

	FindCampaignsForPracticeRequest,

	FindCarePlanItemsByCategoryRequest,

	FindCategoriesRequest,

	FindCollectionsPaymentInvoicesRequest,

	FindDynamicTestHistoryRequest,

	FindEyefinityOrderVendorsRequest,

	FindFamilyHealthHistoryReviewSetListForPatientEncounterRequest,

	FindFamilyHealthHistoryReviewSetListForPatientRequest,

	FindInsurancePaymentInvoicesRequest,

	FindManualVendorsRequest,

	FindNonPagedConnectCampaignMessagesRequest,

	FindNotesByParentEntityRequest,

	FindOrderProcessorsRequest,

	FindPagedConnectCampaignMessagesRequest,

	FindPatientPaymentInvoicesRequest,

	FindPracticeDiagnosesRequest,

	FindQueriesRequest,

	FindQueryTriggersRequest,

	FindRelatedCategoriesRequest,

	FindScheduleItemRequest,

	FindScheduleItemsByIdRequest,

	FindSmartflowOrderVendorsRequest,

	FindTodaysPatientsAppointmentsForProviderRequest,

	FindTwoWayTextConversationMessageUpdatesRequest,

	FindTwoWayTextConversationUpdatesRequest,

	FindUnassignedDeviceMeasurementsRequest,

	FindVCodeRequest,

	FindWorkflowScreenRequest,

	FindWorkflowScreensRequest,

	FormComponentPositionResponse,

	FormComponentResponse,

	FormFieldResponse,

	FormFieldSelectionResponse,

	FormFieldStepperResponse,

	FormLabelResponse,

	FormPositionResponse,

	ForwardDataResponse,

	FrameColorResponse,

	FrameOrderResponse,

	FrameSizeResponse,

	FramesDataSubscriptionResponse,

	GenerateClinicalSummaryRequest,

	GetLatestFamilyHealthHistoryForPatientRequest,

	GetPracticeLaunchUrlRequest,

	GlaucomaCareMedicationPrescriptionResponse,

	GlaucomaCareResponse,

	GlaucomaCareServiceHistoryResponse,

	GlaucomaDataResponse,

	GrantCreditsRequest,

	GuarantorNameResponse,

	HistoryDiagnosisCarePlanItemResponse,

	Hl7ValueSetResponse,

	HumanReadableInsuranceRemittanceResponse,

	ImportMasterQueriesRequest,

	ImportResultResponse,

	IncorporateDrugAllergyRequest,

	IncorporateDrugAllergyUpdateRequest,

	IncorporateExternalDataRequest,

	IncorporateMedicationPrescriptionRequest,

	IncorporatePersonDiagnosisRequest,

	InsuranceClaimProcessorResponse,

	InsuranceClaimStatusResponse,

	InsuranceCoveragesResponse,

	InsuranceInvoiceTransferSplitRequest,

	InsuranceRefundRequest,

	IntakeAllergyListResponse,

	IntakeAllergyResponse,

	IntakeInsuranceListResponse,

	IntakeInsuranceResponse,

	IntakeMedicationListResponse,

	IntakeMedicationResponse,

	InterviewHistoryResponse,

	InterviewResponse,

	InterviewTemplateDetailResponse,

	InterviewTemplateResponse,

	InterviewTemplateSummaryResponse,

	InventoryServiceResponse,

	InvoiceAppointmentResponse,

	InvoiceDashboardResponse,

	InvoiceDashboardSearchRequest,

	InvoiceDateResponse,

	InvoiceDiagnosesRequest,

	InvoiceInsuranceClaimResponse,

	InvoiceItemAdjustmentResponse,

	InvoiceItemDetailResponse,

	InvoiceItemDiagnosisResponse,

	InvoiceItemListResponse,

	InvoiceItemModifierResponse,

	InvoiceItemPaymentRequest,

	InvoiceItemPaymentResponse,

	InvoiceItemPersonDiagnosisResponse,

	InvoiceItemPracticeDiagnosisResponse,

	InvoiceItemResponse,

	InvoiceItemTaxResponse,

	InvoiceResponse,

	InvoiceTransferRequest,

	InvoiceTransferResponse,

	InvoicesUpdatedResponse,

	IopReadingResponse,

	IsPayOnlineUrlPathValidRequest,

	ItemQuantityChangeResponse,

	KeratometryTestResponse,

	LabOrderListResponse,

	LabOrderResponse,

	LabVendorResponse,

	LaunchRxntResponse,

	LedgerPostingReportRequest,

	LedgerPostingReportResponse,

	LocationLevelPrescriptionExpirationRequest,

	LocationProductForOrderResponse,

	LocationProductItemForBarcodePrintingResponse,

	LocationProductItemForOrderResponse,

	LocationProductItemTableResponse,

	LocationProductTableResponse,

	LocationSelectRequest,

	LocationTaxResponse,

	LoginUsingExistingSessionRequest,

	LoincCodeRequest,

	LoincCodeResponse,

	ManualProcessPaymentRequest,

	ManufacturerForPatientFrameResponse,

	ManufacturerResponse,

	ManufacturerSummaryResponse,

	MappedTestFieldRequest,

	MappedTestRequest,

	MarkClaimAsSubmittedRequest,

	MarkClaimsAsCancelledRequest,

	MarkMessageParticipantPersonsNewRequest,

	MasterBaudRateResponse,

	MasterDeviceResponse,

	MasterFileDeviceResponse,

	MasterQueryResponse,

	MasterReferenceDataResponse,

	MasterReferenceEthnicityDataResponse,

	MasterReferenceLanguageDataResponse,

	MasterReferenceRaceDataResponse,

	MasterSerialDeviceResponse,

	MeaningfulUseReportRequest,

	MeaningfulUseReportResponse,

	MedicalDecisionMakingResponse,

	MedicationPrescriptionImportResponse,

	MedicationPrescriptionListResponse,

	MedicationPrescriptionRefillRequest,

	MedicationPrescriptionRequest,

	MedicationPrescriptionResponse,

	MedicationReviewHistoryRequest,

	MedicationReviewHistoryResponse,

	MentalStatusResponse,

	MergePatientsRequest,

	MessageDetailResponse,

	MessageFolderResponse,

	MessageParticipantNameResponse,

	MessageParticipantResponse,

	MessagingDirectProviderResponse,

	MinMaxInventoryReportResponse,

	MipsQualityReportRequest,

	MipsQualityReportResponse,

	MipsQualityResultResponse,

	ModifierResponse,

	MoveFolderRequest,

	MovePatientFileRequest,

	NoteResponse,

	OcularDiffResponse,

	OcularHistoryRequest,

	OcularHistoryResponse,

	OdOsLocationProductIdsActiveResponse,

	OfficeClosedCalendarResponse,

	OfficeClosedResponse,

	OfficeClosedSidebarResponse,

	OpenEdgeCardReaderResponse,

	OpenEdgeConfigurationResponse,

	OpenEdgeCreatePayFieldsTransactionRequest,

	OpenEdgeCreateSavedCardRequest,

	OpenEdgeCreateStoredCardTransactionRequest,

	OpenEdgeFinishTransactionRequest,

	OpenEdgePaymentTransactionResponse,

	OpenEdgePracticeLocationResponse,

	OpenEdgeSaveCardResponse,

	OpenEdgeSettingsRequest,

	OpenEdgeSettingsResponse,

	OpenEdgeSetupTransportKeysRequest,

	OpenEdgeTransactionDetailsResponse,

	OpenEdgeTransportKeysResponse,

	OpenEdgeVoidTransactionResponse,

	OpenOrderResponse,

	OpticalDeviceLensMeasurementResponse,

	OpticalDeviceMeasurementResponse,

	OpticalOrderContactLensDetailResponse,

	OpticalOrderDetailResponse,

	OpticalOrderEyeglassDetailResponse,

	OpticalOrderPodContactLensResponse,

	OpticalOrderPodEyeglassResponse,

	OpticalOrderPodFrameResponse,

	OpticalOrderPodOrderResponse,

	OpticalOrderPodOrderSearchRequest,

	OpticalSummaryEncounterHistoryResponse,

	OpticalSummaryLatestFindingsContactLensResponse,

	OpticalSummaryLatestFindingsContactLensTrialResponse,

	OpticalSummaryLatestFindingsEyeglassResponse,

	OpticalSummaryLatestFindingsRefractionResponse,

	OpticalSummaryLatestFindingsResponse,

	OrderContactLensPackagingResponse,

	OrderContactLensPrescriptionResponse,

	OrderContactLensTrialResponse,

	OrderCountsByStatusResponse,

	OrderDashboardSortRequest,

	OrderEyefinityFeeLensResponse,

	OrderEyefinityFeeMappingResponse,

	OrderEyefinityFeeOptionResponse,

	OrderEyefinityFeeScheduleListResponse,

	OrderEyefinityFeeScheduleResponse,

	OrderEyefinityFeeServiceResponse,

	OrderEyeglassPrescriptionResponse,

	OrderForPrintResponse,

	OrderOnHoldHistoryResponse,

	OrderPerformedServiceResponse,

	OrderPrescriptionResponse,

	OrderProcessorConfigResponse,

	OrderProductColorResponse,

	OrderProductOptionRequest,

	OrderProductOptionResponse,

	OrderQueryResponse,

	OrderResponse,

	OrderStatusHistoryResponse,

	OrderStatusResponse,

	OrderStatusUpdateRequest,

	OrderTwelve84ConfigurationOptionResponse,

	OrderTwelve84ConfigurationResponse,

	OrderTwelve84FrameResponse,

	OrderTwelve84Response,

	OrderVendorConfigResponse,

	OrderVendorResponse,

	OrdersAdvancedSearchRequest,

	OrdersSearchResponse,

	OrdersSimpleSearchRequest,

	OtherAllergyResponse,

	PagingAndSortingRequest,

	ParseExternalPatientDataFileRequest,

	PatientAccountBalancesResponse,

	PatientAccountSummaryResponse,

	PatientAddWorkflowScreenToWorkflowStepRequest,

	PatientAdditionalInformationRequest,

	PatientBasicInformationRequest,

	PatientBirthdaySearchRequest,

	PatientBirthdaySearchResponse,

	PatientCommentResponse,

	PatientCommunicationEligibilityResponse,

	PatientCommunicationPreferencesRequest,

	PatientCommunicationPreferencesResponse,

	PatientCommunicationResponse,

	PatientConsentResponse,

	PatientContactEmergencyContactStatusRequest,

	PatientContactInformationResponse,

	PatientCreditRequest,

	PatientCvxCodeResponse,

	PatientDashboardSummaryResponse,

	PatientDataExportResponse,

	PatientDocumentContentRequest,

	PatientDocumentResponse,

	PatientDocumentSummaryResponse,

	PatientDuplicateSearchRequest,

	PatientDuplicateSearchResponse,

	PatientEmergencyContactResponse,

	PatientEmploymentRequest,

	PatientEmploymentResponse,

	PatientFamilyInformationResponse,

	PatientFileInfoResponse,

	PatientFileListRequest,

	PatientFileListResponse,

	PatientGuarantorResponse,

	PatientHealthConcernRequest,

	PatientHealthConcernResponse,

	PatientHealthGoalResponse,

	PatientHeuristicSearchResponse,

	PatientHl7ValueSetResponse,

	PatientHl7ValueSetSearchRequest,

	PatientImplantableDeviceResponse,

	PatientInvoiceDashboardSearchRequest,

	PatientInvoiceTransferSplitRequest,

	PatientManagementSearchRequest,

	PatientMinerSearchRequest,

	PatientMinerSearchResponse,

	PatientNameResponse,

	PatientOngoingCareResponse,

	PatientOrderHistoryResponse,

	PatientOtherInformationRequest,

	PatientPaymentResponse,

	PatientPerformedServiceResponse,

	PatientPharmacyResponse,

	PatientPhoneInformationRequest,

	PatientPodConnectMessageResponse,

	PatientPodConnectMessagesResponse,

	PatientPodContactLensEyeResponse,

	PatientPodContactLensResponse,

	PatientPodContactResponse,

	PatientPodEncounterResponse,

	PatientPodEyeglassResponse,

	PatientPodFamilyAccountMemberResponse,

	PatientPodFamilyMembersResponse,

	PatientPodLabOrderResponse,

	PatientPodLastAndNextEncounterResponse,

	PatientPodLastEncounterResponse,

	PatientPodMedicationResponse,

	PatientPodNextAppointmentResponse,

	PatientPodNoteResponse,

	PatientPodNotesResponse,

	PatientPodOrderResponse,

	PatientPodPatientAccountResponse,

	PatientPodPersonDiagnosisResponse,

	PatientPodPersonInsuranceResponse,

	PatientPodRecallResponse,

	PatientPodTaskResponse,

	PatientPopoverResponse,

	PatientPortalAccessRequest,

	PatientPortalAuthorizedPatientResponse,

	PatientPortionRequest,

	PatientProviderResponse,

	PatientQuickViewResponse,

	PatientRecallDetailResponse,

	PatientRecallResponse,

	PatientRecallSearchRequest,

	PatientRecallSearchResponse,

	PatientReferralRequest,

	PatientReferralResponse,

	PatientRefundRequest,

	PatientResponse,

	PatientSchedulingInfoResponse,

	PatientSchedulingPreferenceResponse,

	PatientSearchByIdsRequest,

	PatientSearchRequest,

	PatientSearchResponse,

	PatientSearchStringRequest,

	PatientSimpleSearchRequest,

	PatientTargetIopResponse,

	PatientTaskResponse,

	PatientTriggerOutcomeResponse,

	PatientVaccinationListResponse,

	PatientVaccinationRequest,

	PatientVaccinationResponse,

	PatientVaccineManufacturerResponse,

	PatientWorkflowMenuResponse,

	PatientWorkflowScreenMenuResponse,

	PatientWorkflowScreenTemplateListResponse,

	PatientWorkflowStepMenuResponse,

	PatientWorkflowTestTemplateListResponse,

	PaymentDashboardResponse,

	PaymentDashboardSearchRequest,

	PaymentGroupResponse,

	PaymentInfoResponse,

	PaymentInvoiceListResponse,

	PaymentManualInvoiceSearchRequest,

	PaymentPreferencesResponse,

	PaymentProcessorPracticeLocationResponse,

	PaymentResponse,

	PaymentStoredTokenResponse,

	PaymentTransactionReceiptResponse,

	PaymentTransactionResponse,

	PaymentTransferInvoiceItemsResponse,

	PerformedServiceResponse,

	PersonCoverageResponse,

	PersonDetailsRequest,

	PersonDetailsResponse,

	PersonDiagnosisImportResponse,

	PersonDiagnosisResponse,

	PersonDiagnosisWithCarePlansResponse,

	PersonGeneralHistoryResponse,

	PersonInsuranceCompanyResponse,

	PersonInsuranceDetailsResponse,

	PersonInsuranceDropdownResponse,

	PersonInsurancePolicyResponse,

	PersonInsuranceResponse,

	PersonInsuranceSummaryResponse,

	PersonInsuranceTableResponse,

	PersonInsuranceTooltipResponse,

	PersonNameResponse,

	PfshRequest,

	PfshResponse,

	PharmacyBrandResponse,

	PharmacyResponse,

	PharmacySummaryResponse,

	PhoneInformationRequest,

	PhoneNumbersResponse,

	PostalCodeSearchRequest,

	PqriAlertRequest,

	PqriAlertResponse,

	PracticeAndLocationsIdentifiersResponse,

	PracticeConsentFormResponse,

	PracticeDiagnosisListResponse,

	PracticeDiagnosisResponse,

	PracticeDrugListResponse,

	PracticeDrugResponse,

	PracticeFamilyHealthHistoryFilterListResponse,

	PracticeFamilyHealthHistoryFilterRequest,

	PracticeFamilyHistoryFiltersSearchRequest,

	PracticeFileInfoResponse,

	PracticeFileRequest,

	PracticeFileResponse,

	PracticeGeneralInfoResponse,

	PracticeIdentifiersResponse,

	PracticeInsuranceCompanyDropdownResponse,

	PracticeInsuranceCompanyModalResponse,

	PracticeInsuranceCompanyResponse,

	PracticeInsuranceCompanySummaryResponse,

	PracticeInsuranceRemittanceCodeAdjustmentResponse,

	PracticeLocationAccessCodeResponse,

	PracticeLocationDefaultLabelPrinterResponse,

	PracticeLocationDeviceResponse,

	PracticeLocationExternalAccessSummaryResponse,

	PracticeLocationFileDeviceResponse,

	PracticeLocationIdentifiersResponse,

	PracticeLocationOfficeHoursResponse,

	PracticeLocationRevEngageSettingsResponse,

	PracticeLocationSerialDeviceResponse,

	PracticeLocationSubscriberResponse,

	PracticeLocationSummaryResponse,

	PracticeLocationTaxRequest,

	PracticeLocationTransferTaxResponse,

	PracticeLocationValidDirectMessageResponse,

	PracticeRemittanceCodeAdjustmentResponse,

	PracticeSmartApplicationResponse,

	PracticeTemplateSummaryResponse,

	PreferenceResponse,

	PrescriptionSelectContactLensEyeResponse,

	PrescriptionSelectContactLensResponse,

	PresentIllnessResponse,

	PreviewBillableItemResponse,

	PreviewMappedTestsRequest,

	PreviousAddressRequest,

	PreviousAddressResponse,

	PrintStatementsRequest,

	ProductBrandCollectionResponse,

	ProductBrandForPatientFrameResponse,

	ProductBrandResponse,

	ProductCategoryByMasterProductCategoryRequest,

	ProductCategoryResponse,

	ProductCollectionForPatientFrameResponse,

	ProductColorResponse,

	ProductForPrescriptionResponse,

	ProfessionalTimeAdjustmentResponse,

	ProfessionalTimeResponse,

	ProfessionalTimeSessionResponse,

	PropertyValuesResponse,

	ProviderInsuranceIdentifierResponse,

	ProviderResponse,

	ProviderWithLocationsResponse,

	PullForwardEncounterRequest,

	PupillaryDistanceHistoryResponse,

	QueryCriteriaFieldRequest,

	QueryCriteriaFieldResponse,

	QueryFieldCategoryResponse,

	QueryFieldOptionResponse,

	QueryFieldResponse,

	QueryResponse,

	QueryResultFieldResponse,

	QueryResultResponse,

	QueryResultValueResponse,

	QueryResultsResponse,

	QuerySelectFieldRequest,

	QuerySelectFieldResponse,

	QuerySortFieldRequest,

	QuerySortFieldResponse,

	QuerySummaryResponse,

	QueryTemplateCategoryResponse,

	QueryTriggerCarePlanItemTemplateResponse,

	QueryTriggerCarePlanTemplateResponse,

	QueryTriggerResponse,

	QueryTriggerSourceResponse,

	QueryTriggerSummaryResponse,

	QuickAddContactRequest,

	QuickAddFamilyMemberRequest,

	QuickAddPatientRequest,

	ReasonForVisitResponse,

	ReceiptsReportRequest,

	ReceiptsReportResponse,

	ReceivePaymentPayerRequest,

	ReceivePaymentPayerResponse,

	ReceivePaymentTransferInvoiceItemsRequest,

	ReconciliationBatchResponse,

	ReconciliationItemResponse,

	ReconciliationReportResponse,

	RecurPatternDailyResponse,

	RecurPatternMonthlyResponse,

	RecurPatternWeeklyResponse,

	RecurPatternYearlyResponse,

	RecurringDefinitionResponse,

	ReferenceDataResponse,

	ReferenceDataSummaryResponse,

	RefillContactLensPrescriptionRequest,

	RefillEyeglassPrescriptionRequest,

	RefractionEyeRequest,

	RefractionEyeResponse,

	RefractionResponse,

	RefractiveCareResponse,

	RefundInsuranceResponse,

	RefundPatientResponse,

	RefundsReportRequest,

	RefundsReportResponse,

	RelatedCategoryResponse,

	RemakeEyefinityOrderRequest,

	RemakeOrderRequest,

	RemoveInvoiceItemRequest,

	ReplyAllDataResponse,

	ReplyDataResponse,

	ResetClaimsRequest,

	ResolvePersonDiagnosisRequest,

	ReturnOrderRequest,

	RevClearConfigurationResponse,

	RevClearExternalCredentialResponse,

	RevDirectRegisterLocationRequest,

	RevDirectResponse,

	ReviewOfSystemsDiffResponse,

	ReviewOfSystemsRequest,

	ReviewOfSystemsResponse,

	ReviewPatientFamilyHealthHistoryReviewSetRequest,

	RunPatientTriggersRequest,

	RunQueryRequest,

	RxSelectContactLensResponse,

	RxSelectEyeglassResponse,

	RxntCompanyMapResponse,

	RxntCredentialRequest,

	RxntPostResponse,

	SalesReportRequest,

	SalesReportResponse,

	SalesRepresentativeResponse,

	SalesRepresentativesSummaryResponse,

	SaveMessageFileAsPatientFileRequest,

	ScheduleFilterRequest,

	ScheduleFilterResponse,

	ScheduleItemCalendarResponse,

	ScheduleItemPrintResponse,

	ScheduleItemResponse,

	ScheduleItemSidebarResponse,

	ScheduleItemsWithDeletedResponse,

	ScheduleViewPreferencesResponse,

	SchedulingPreferencesResponse,

	SearchActivePracticeDiagnosesForAssessmentRequest,

	SearchAppointmentSlotRequest,

	SearchFeeScheduleItemsRequest,

	SearchLoincCodesRequest,

	SearchMessageParticipantPersonsRequest,

	SearchPatientFilesRequest,

	SearchPracticeDiagnosesRequest,

	SearchRefractionTestsRequest,

	SearchSnomedCodesRequest,

	SecureResourceCategoryResponse,

	SecureResourceResponse,

	SecurityRoleResponse,

	SendFeedbackRequest,

	SendOrderConnectMessageRequest,

	SendStatementsRequest,

	ServiceAdjustmentResponse,

	ServiceLineResponse,

	ServiceSummaryResponse,

	SignEncounterRequest,

	SignEncounterSsoRequest,

	SmartflowMasterManufacturerResponse,

	SmartflowPracticeSupplierResponse,

	SmartflowSupplierLocationResponse,

	SmartflowSupplierResponse,

	SnomedCodeResponse,

	SocialDiffResponse,

	SocialHistoryRequest,

	SocialHistoryResponse,

	SortingRequest,

	SplitPaymentRequest,

	StartAppointmentCalendarResponse,

	StartAppointmentRequest,

	StatementInvoiceResponse,

	StatementPayerResponse,

	StopPrescriptionRequest,

	StringAdditionalPreferenceRequest,

	StringAdditionalPreferenceResponse,

	StringValueDiffResponse,

	SubmitClaimsRequest,

	SubscribeTwelve84SubscriptionRequest,

	SurgeryHistoryResponse,

	SystemPreferencesResponse,

	TargetIopResponse,

	TaxResponse,

	TemplateCategorySearchRequest,

	ThirdPartyAdministratorResponse,

	TodaysPatientsAppointmentResponse,

	TransactionResponse,

	TransferInvoiceBalanceToPatientRequest,

	TransferInvoiceItemsRequest,

	TransferItemRequest,

	TransferItemResponse,

	TransferPatientCreditRequest,

	TrizettoExternalCredentialResponse,

	Twelve84AgreementResponse,

	Twelve84SubscriptionResponse,

	Twelve84SubscriptionTableResponse,

	TwoWayTextAutomatedMessageResponse,

	TwoWayTextConversationListResponse,

	TwoWayTextConversationOrPatientMatchesResponse,

	TwoWayTextConversationResponse,

	TwoWayTextFindConversationsByClassificationRequest,

	TwoWayTextFindNewConversationPatientsRequest,

	TwoWayTextMessageResponse,

	TwoWayTextMessageSummaryResponse,

	TwoWayTextNewConversationPatientResponse,

	TwoWayTextPatientMatchResponse,

	TwoWayTextPatientMatchesResponse,

	TwoWayTextSimpleSearchRequest,

	UnassignedServiceResponse,

	UnassignedServicesRequest,

	UnlockSessionRequest,

	UnsignEncounterRequest,

	UnsignEncounterSsoRequest,

	UpdateAdditionalClaimInformationRequest,

	UpdateAlertRequest,

	UpdateAllergyReviewHistoryRequest,

	UpdateAmendmentRequest,

	UpdateAppointmentDescriptionRequest,

	UpdateAppointmentSlotRequest,

	UpdateAssessmentPersonDiagnosisRequest,

	UpdateAssessmentPrimaryDiagnosisForEncounterRequest,

	UpdateAssessmentRequest,

	UpdateBusyTimeRequest,

	UpdateCarePlanItemTemplateEducationRequest,

	UpdateCarePlanItemTemplateGeneralRequest,

	UpdateCarePlanItemTemplateGoalRequest,

	UpdateCarePlanItemTemplateLabOrderRequest,

	UpdateCarePlanItemTemplateMedicationPrescriptionRequest,

	UpdateCarePlanItemTemplateRecallRequest,

	UpdateCarePlanTemplateRequest,

	UpdateCategoryRequest,

	UpdateCategorySortOrderRequest,

	UpdateCheckoutTaskRequest,

	UpdateClaimProcessingPreferencesRequest,

	UpdateClaimsProcessorAttributeRequest,

	UpdateCommunicationsPreferencesRequest,

	UpdateConditionRelatedToRequest,

	UpdateConnectCampaignDraftRequest,

	UpdateConnectCampaignScheduleRequest,

	UpdateConnectPracticeRequest,

	UpdateContactLensHistoryRequest,

	UpdateContactLensOrderRequest,

	UpdateContactLensPrescriptionDeliveryMethodRequest,

	UpdateContactLensPrescriptionRequest,

	UpdateContactLensTrialOrderRequest,

	UpdateContactLensTrialRequest,

	UpdateContactRequest,

	UpdateCustomFilterPreferencesRequest,

	UpdateCustomQueryRequest,

	UpdateDescriptionAndCommentsCheckoutTaskRequest,

	UpdateDiagnosesOnPerformedServiceRequest,

	UpdateDrugAllergyRequest,

	UpdateEmployeeOfficeHoursRequest,

	UpdateEncounterProviderRequest,

	UpdateEncounterRoleRequest,

	UpdateEncounterTemplateRequest,

	UpdateExternalCredentialRequest,

	UpdateExternalDataRequest,

	UpdateExternalProviderRequest,

	UpdateEyefinityContactLensOrderRequest,

	UpdateEyefinityEyeglassOrderRequest,

	UpdateEyefinityFrameOrderRequest,

	UpdateEyefinityItemsAsBillableRequest,

	UpdateEyefinityItemsAsNonBillableRequest,

	UpdateEyefinityOrderRequest,

	UpdateEyeglassLensMeasurementsRequest,

	UpdateEyeglassLensOrderDetailRequest,

	UpdateEyeglassOrderRequest,

	UpdateEyeglassPrescriptionRequest,

	UpdateFamilyAccountMemberRequest,

	UpdateFamilyAccountMemberWithEmergencyContactRequest,

	UpdateFeatureFlagEmployeeRequest,

	UpdateFeeScheduleRangeRequest,

	UpdateFeeScheduleRequest,

	UpdateFolderRequest,

	UpdateFrameOrderRequest,

	UpdateFramesDataSubscriptionRequest,

	UpdateGeneralMedicationPrescriptionRequest,

	UpdateIntakeAllergiesRequest,

	UpdateIntakeMedicationRequest,

	UpdateInterviewRequest,

	UpdateInterviewTemplateRequest,

	UpdateInvoiceAppointmentRequest,

	UpdateInvoiceDateRequest,

	UpdateInvoiceEncounterRequest,

	UpdateInvoiceFinanceChargePlanRequest,

	UpdateInvoiceItemQuantityRequest,

	UpdateInvoiceItemRequest,

	UpdateInvoiceItemResponsiblePersonListRequest,

	UpdateInvoiceItemResponsiblePersonRequest,

	UpdateInvoiceLocationRequest,

	UpdateInvoiceProviderRequest,

	UpdateLabOrderRequest,

	UpdateManufacturerRequest,

	UpdateMedicalDecisionMakingRequest,

	UpdateMedicationPrescriptionRequest,

	UpdateMedicationReviewHistoryRequest,

	UpdateMentalStatusRequest,

	UpdateMessageParticipantPersonFolderRequest,

	UpdateNonBillableRequest,

	UpdateNoteRequest,

	UpdateNotificationPersonsRequest,

	UpdateOfficeClosedRequest,

	UpdateOfficeHoursDailyRequest,

	UpdateOfficeHoursWeeklyRequest,

	UpdateOpenEdgeCardReaderRequest,

	UpdateOpenEdgeSettingsRequest,

	UpdateOrderOnHoldRequest,

	UpdateOrderRequest,

	UpdateOrderStatusRequest,

	UpdateOtherAllergyRequest,

	UpdatePasswordPolicyRequest,

	UpdatePatientCommentRequest,

	UpdatePatientConsentRequest,

	UpdatePatientFileRequest,

	UpdatePatientGeneralBenefitsRequest,

	UpdatePatientGeneralBenefitsSelfPolicyRequest,

	UpdatePatientHealthConcernRequest,

	UpdatePatientHealthGoalRequest,

	UpdatePatientImplantableDeviceRequest,

	UpdatePatientInsuranceBasicInformationRequest,

	UpdatePatientInsuranceBasicInformationSelfPolicyRequest,

	UpdatePatientMaterialBenefitsRequest,

	UpdatePatientMaterialBenefitsSelfPolicyRequest,

	UpdatePatientOngoingCareRequest,

	UpdatePatientProviderRequest,

	UpdatePatientQuickViewRequest,

	UpdatePatientRecallRequest,

	UpdatePatientRequest,

	UpdatePatientTriggerRequest,

	UpdatePatientVaccinationRequest,

	UpdatePaymentGroupRequest,

	UpdatePaymentPreferencesRequest,

	UpdatePersonDiagnosisLocationAndQualifierRequest,

	UpdatePersonDiagnosisRequest,

	UpdatePersonGeneralHistoryRequest,

	UpdatePersonInsuranceAndPolicyRequest,

	UpdatePersonInsuranceRequest,

	UpdatePharmacyRequest,

	UpdatePracticeConsentFormRequest,

	UpdatePracticeDiagnosisRequest,

	UpdatePracticeDrugRequest,

	UpdatePracticeFamilyHealthFiltersRequest,

	UpdatePracticeFileRequest,

	UpdatePracticeGeneralInfoRequest,

	UpdatePracticeInsuranceCompanyRequest,

	UpdatePracticeInsuranceRemittanceCodeAdjustmentRequest,

	UpdatePracticeLocationTaxesRequest,

	UpdatePracticeLocationTransferTaxRequest,

	UpdatePracticeLocationTransferTaxesRequest,

	UpdatePracticeModifiersRequest,

	UpdatePracticeRemittanceCodeAdjustmentRequest,

	UpdatePresentIllnessRequest,

	UpdateProviderInsuranceIdentifierRequest,

	UpdateQueryRequest,

	UpdateQueryTriggerRequest,

	UpdateReasonForVisitForSignedEncounterRequest,

	UpdateReasonForVisitRequest,

	UpdateRecurringAppointmentSlotRequest,

	UpdateRecurringBusyTimeRequest,

	UpdateRecurringDefinitionRequest,

	UpdateRecurringEmployeeOfficeHoursRequest,

	UpdateRecurringOfficeClosedRequest,

	UpdateReferenceDataRequest,

	UpdateReferenceDataSortOrderRequest,

	UpdateRelatedCategoryRequest,

	UpdateRevClearConfigurationRequest,

	UpdateRevClearExternalCredentialRequest,

	UpdateSalesRepresentativeRequest,

	UpdateScheduleViewPreferencesRequest,

	UpdateSchedulingPreferenceRequest,

	UpdateSurgeryHistoryRequest,

	UpdateThirdPartyAdministratorRequest,

	UpdateTrizettoExternalCredentialRequest,

	UpdateTwelve84SubscriptionRequest,

	UpdateTwoWayTextConversationClassificationRequest,

	UpdateTwoWayTextConversationPatientRequest,

	UpdateUserRoleRequest,

	UpdateVendorLocationConfigRequest,

	UpdateVendorRequest,

	UpdateWaitingListRequest,

	UpdateWorkflowStepTemplateRequest,

	UpdateWorkflowTestsRequest,

	UpdatedAssessmentPersonDiagnosisResponse,

	UserSessionLogRequest,

	UserSessionLogResponse,

	UserSessionLogSearchRequest,

	UserSummaryResponse,

	ValidatePracticeLocationAddressResponse,

	ValidatePracticeRemittanceX12CodeRequest,

	ValidateUsernameRequest,

	ValidatedCreateAppointmentResponse,

	VendorLocationConfigResponse,

	VendorResponse,

	VendorSearchRequest,

	VendorSummaryResponse,

	VerifyInsuranceRequest,

	VisionWebSupplierResponse,

	VoidInsuranceCreditRequest,

	VoidInsuranceRefundRequest,

	VoidInvoiceRequest,

	VoidPatientCreditRequest,

	VoidPatientRefundRequest,

	VoidPaymentRequest,

	WaitingListResponse,

	WebServiceUserResponse,

	WorkflowScreenTemplatePresentIllnessResponse,

	WorkflowScreenTestTemplateLayoutRequest,

	WorkflowStepTemplateResponse,

	WorkflowStepTemplateScreenResponse,

	WorkflowTemplateStepRequest,

	WorkflowTestRequest,

	WorkflowTestTriggersResultResponse,

	WriteoffInvoiceTransferSplitRequest,

	X12CodeExistsRequest,

};
